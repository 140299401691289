import React from "react";
import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./services/AuthContext";
import { LOGIN } from "./constant/constant";

const PrivateRouteBuyer = ({ children }) => {
  const { token } = useContext(AuthContext);
  if (token) {
    const role = token.userRole.toLowerCase();
    if (role === "customer") {
      return children;
    }
  }
  return <Navigate to={LOGIN} replace />;
};

export default PrivateRouteBuyer;
