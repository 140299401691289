import React, { createContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";

import {
  ACTIVE_ACCOUNT,
  API_URL,
  HOME_BUYER_PAGE,
  HOME_SCREEN_PAGE,
  LOGIN,
  PROJECT_EXISTING_PAGE,
  PROJECT_IN_PROGRESS_PAGE,
  PROJECT_PUBLISH,
  RESET_PWD,
  config,
} from "../constant/constant";

const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(() =>
    localStorage.getItem("accessToken")
      ? jwtDecode(localStorage.getItem("accessToken")).user
      : null,
  );
  const [user, setUser] = useState();
  const [profile, setProfile] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const urlString = window.location.href;

  const refreshToken = async (user, refreshToken) => {
    try {
      const response = await axios.post(`${API_URL}refreshToken`, {
        user: user,
        refreshToken: refreshToken,
      });
      return response.data;
    } catch (error) {
      throw new Error("Token refresh failed");
    }
  };

  // inscription
  const registerUser = async (formData, setVisible, setLoading, setMsg) => {
    setVisible(true);
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}account/signup`, formData);

      if (response.status === 200) {
        setLoading(false)
        setMsg(t("Register.registrationFailedThisEmailAddressAlreadyExists"));
      } else if (response.status === 201) {
        window.location.href = ACTIVE_ACCOUNT;
      } else {
        setLoading(false)
        setMsg(t("Register.registrationFailed"));
      }
    } catch (error) {
      setLoading(false)
      setMsg(t("Register.registrationFailed"));
      return error;
    }
  };

  // login
  const loginUser = async (formData) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await axios.post(`${API_URL}account/signin`, formData);
        console.log(res)
        if (res.status === 200) {
          const data = res.data;
          const decoded = jwtDecode(data.accessToken);
          const userDetail = decoded.user;
          setToken(userDetail);
        }
        resolve(res);
      } catch (error) {
        reject(error);
      }
    })
  };

  // google login
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  // verify email client auth
  const verifyEmailLoginClientAuth = async (id, social_auth_token, expires_in, setVisible) => {
    try {
      await axios
        .post(`${API_URL}account/verify-email-login-auth`, {
          id: id,
          social_auth_token: social_auth_token,
          expires_in: expires_in,
        })
        .then((res) => {
          if (res.status === 200) {
            // si l'utilisateur s'est déjà connecté une fois, alors on stoke le token et le expires_in du nouveau connexion
            const data = res.data;
            const decoded = jwtDecode(data.accessToken);
            const userDetail = decoded.user;
            setToken(userDetail);

            if (data.typeAccount === "clientAuth") {
              localStorage.setItem("userId", userDetail.id);
            }

            localStorage.setItem("accessToken", data.accessToken);

            if (userDetail.userRole === "Entrepreneur" || userDetail.userRole === "Contractor") {
              window.location.href = PROJECT_PUBLISH;
            } else {
              window.location.href = PROJECT_IN_PROGRESS_PAGE;
            }
          } else {
            // si l'utilisateur ne s'est jamais connecté par les client auth
            // on lui affiche le formulaire pour qu'il complète ces informations
            setVisible(true);
          }
        });
    } catch (error) {
      return error;
    }
  };

  const activateAccount = async (code, setVisible, setLoading, setMsg, setDone) => {
    setVisible(true);
    
    try {
      await axios
        .post(`${API_URL}account/active-account`, {
          code: code,
        })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false)
            setDone(true);
            setMsg(t("Register.yourAccountWasSuccessfullyActivated"));
          } else {
            setLoading(false);
            setMsg(t("Register.errorActivatingYourAccountPleaseTryAgain"));
          }
        });
    } catch (error) {
      setLoading(false);
      setMsg(t("Register.errorActivatingYourAccountPleaseTryAgain"));
      return error;
    }
  };

  const verifyEmail = async (email, showToast, setVisible) => {
    try {
      await axios
        .post(`${API_URL}account/verify-email`, {
          email: email,
          title: t("Login.confirmYourEmail"),
          text: t("Login.clickOnTheButtonBelowToConfirmYourEmail"),
          btnText: t("common.confirm"),
          subject: t("Login.confirmPassword"),
          origin: window.location.origin,
        })
        .then((response) => {
          if (response.status === 200) {
            setVisible(true);
          } else {
            showToast(t("common.thisEmailAddressDoesntExist"));
          }
        });
    } catch (error) {
      showToast(t("common.unknownErrorPleaseTryAgain"));
      return error;
    }
  };

  const confirmEmail = async (code, showToast) => {
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);
    const searchParams = urlObject.searchParams;
    const queryString = searchParams.toString();
    try {
      await axios
        .post(`${API_URL}account/confirm-email`, { code: code, token: queryString })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("userId", response.data.id);
            window.location.href = RESET_PWD;
          } else {
            showToast(t("Login.confirmationCodeExpired"));
          }
        });
    } catch (error) {
      showToast(t("common.unknownErrorPleaseTryAgain"));
      return error;
    }
  };

  const resetPwd = async (pwd, showToast) => {
    try {
      await axios
        .post(`${API_URL}account/reset-pwd`, {
          password: pwd,
          userId: localStorage.getItem("userId") ?? 0,
        })
        .then((response) => {
          if (response.status === 200) {
            window.location.href = LOGIN;
          } else {
            showToast(t("common.passwordResetFailed"));
          }
        });
    } catch (error) {
      showToast(t("common.unknownErrorPleaseTryAgain"));
      return error;
    }
  };

  const logoutUser = () => {
    setToken(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    window.location.href = LOGIN;
  };

  const googleLogout = () => {
    googleLogout();
    setProfile(null);
  };

  const getUserDetailsInstagram = async (token) => {
    try {
      await axios
        .get(`https://graph.instagram.com/me?fields=id,username&access_token=${token}`)
        .then((res) => {
          setVisible(true);
          setProfile({
            name: res.data.username,
            id: res.data.id,
          });
          setUser({ access_token: token });
          verifyEmailLoginClientAuth(res.data.id, token, null, setVisible);
        });
    } catch (error) {
      return error;
    }
  };

  const tokenRefreshInterval = setInterval(
    async () => {
      const refreshT = localStorage.getItem("accessToken");
      const clientAuthId = localStorage.getItem("userId");
      if (!clientAuthId) {
        try {
          const newToken = await refreshToken(token, refreshT);
          localStorage.setItem("accessToken", newToken.accessToken);
        } catch (error) {
          console.error(error);
        }
      }
    },
    60 * 60 * 1000,
  );

  const contextData = {
    token,
    registerUser,
    loginUser,
    logoutUser,
    activateAccount,
    verifyEmail,
    confirmEmail,
    resetPwd,

    googleLogin,
    user,
    profile,
    visible,
    setVisible,
    verifyEmailLoginClientAuth,
    setProfile,
    setUser,
  };

  useEffect(() => {
    setLoading(false);

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      console.log(jwtDecode(accessToken).user);
      setToken(jwtDecode(accessToken).user);
    }

    //  google auth
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setProfile(res.data);

          console.log(user);
          console.log(user.access_token);

          // on vérifie si l'utilisateur s'est déjà connecté une fois avec ce compte
          verifyEmailLoginClientAuth(res.data.id, user.access_token, user.expires_in, setVisible);
        })
        .catch((err) => console.log(err));
    }

    // instagram auth

    const urlParams = new URLSearchParams(new URL(urlString).search);
    const code = urlParams.get("code");

    if (code) {
      axios
        .post(`${API_URL}get/user-account-instagram-callback`, {
          code: code,
        })
        .then((res) => {
          console.log(res);
          const accessToken = res.data.access_token;
          if (accessToken) {
            getUserDetailsInstagram(accessToken);
          }
        })
        .catch((err) => console.log(err));
    }

    // return () => clearInterval(tokenRefreshInterval);
  }, [user]);

  return (
    <AuthContext.Provider value={contextData}>{loading ? null : children}</AuthContext.Provider>
  );
};
