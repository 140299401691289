import React from "react";

import "./RadioButton.scss";

export const RadioButton = ({ name, label, setRole }) => {
  return (
    <div className="Checkbox">
      <div className="Checkbox-box">
        <input type="radio" name={name} onClick={() => setRole(label)} />
        <div className="Checkbox-round" />
      </div>
      <span className="Checkbox-label">{label}</span>
    </div>
  );
};
