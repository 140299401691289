import React from "react";
import { FiEye } from "react-icons/fi";

export default function showPassword(className) {
  const showPwd = (className) => {
    const input = document.querySelector(className);

    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }
  };

  return (
    <button type="button" className="EyePwd" onClick={() => showPwd(className)}>
      <FiEye />
    </button>
  );
}
