import React from "react";

import "./Auth.scss";

const Auth = ({ children, isOpenDialogAuth }) => {
  return (
    <div className="Auth flex">
      <button className="Auth-closeForm" type="button" onClick={() => isOpenDialogAuth(false)}>
        &times;
      </button>
      <form className="FormAuth">{children}</form>
      <div className="Auth-imgBox" />
    </div>
  );
};

export default Auth;
