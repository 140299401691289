import React, { Suspense, useContext, useEffect, useState } from "react";
import { AiOutlineSetting, AiOutlineProject } from "react-icons/ai";
import { MdNotificationsNone } from "react-icons/md";
import { LiaProjectDiagramSolid } from "react-icons/lia";
import { BiDetail, BiSolidOffer } from "react-icons/bi";
import { useTranslation } from "react-i18next";

import "./Layout.scss";
import Layout from "./Layout";
import {
  COMPLETED_PROJECTS_PAGE,
  NOTIFICATIONS_CONTRACTOR,
  PROFILE_CONTRACTOR,
  PROJECT_EXISTING_PAGE,
  PROJECT_PUBLISH,
  SETTINGS_CONTRACTOR,
  YOUR_BIDS_PAGE,
} from "../constant/constant";
import SideBar from "../components/Sidebar/Sidebar";
import GlobalContext from "../services/GlobalContext";
import HandleGoBack from "../components/HandleGoBack/HandleGoBack";
import { Dialog } from "primereact/dialog";
import warn from "../assets/images/warning-sign-9762.svg";
import { Button } from "primereact/button";
import AuthContext from "../services/AuthContext";

const LayoutContractor = ({ children, title }) => {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const { getNotificationsByContractor, userProfile } = useContext(GlobalContext);
  const [visible, setVisible] = useState(false);
  const [nbNotifications, setNbNotifications] = useState(0);

  const menuContractor = [
    /* {
      path: PROJECT_PUBLISH,
      label: t("SideBar.projectPublish"),
      icon: <AiOutlineProject />,
    }, */
    {
      path: PROJECT_EXISTING_PAGE,
      label: t("SideBar.yourProjects"),
      icon: <AiOutlineProject />,
    },
    {
      path: PROJECT_PUBLISH,
      label: "Voir les projects dans ma région",
      icon: <LiaProjectDiagramSolid />,
    },
    { path: YOUR_BIDS_PAGE, label: t("SideBar.yourBids"), icon: <BiDetail /> },
    /* {
      path: COMPLETED_PROJECTS_PAGE,
      label: t("SideBar.completedProjects"),
      icon: <BiSolidOffer />,
    }, */
    {
      path: NOTIFICATIONS_CONTRACTOR,
      label: t("SideBar.notifications"),
      icon: <MdNotificationsNone />,
      nbNotify: nbNotifications,
    },
    //{ path: SETTINGS_CONTRACTOR, label: t("SideBar.settings"), icon: <AiOutlineSetting /> },
  ];

  useEffect (() => {
    const url = window.location.pathname;
    if (userProfile) {
      if (!userProfile.codeZip) {
        if (url.includes("profile")) {
          setVisible(false)
        } else {
          setVisible(true);
        }
      } 
    }

    const fetchData = async () => {
      try {
        const res = await getNotificationsByContractor(token?.id);
        console.log(res)
        if (res) {
          if (res.status === 200) {
            const data = res.data;
            const filter = data && data.filter(item => item.isView === false);

            setNbNotifications(filter.length || 0);
          }
        } 
      } catch(error) {
        console.error("Une erreur est survenue lors de la récupération des notifications");
      }
    }

    fetchData();
  },[userProfile]);

  return (
    <Layout isFooter={false}>
      <main className="Main">
      <Dialog visible={visible}
        onHide={() => setVisible(false)}
        closable={false}
        style={{ width: "500px"}}>
          <div className="Main-dialogNotify">
              <img src={warn} alt="" width={80} height={80} />
              <p className="text-center">{t("common.youNeedToDefineTheipCodeOfYourLocationThisWillHelpUsAssignYouToProjectsInYourAreaGoToYourProfileToUpdate")}</p>
              <Button label={t("common.myProfile")} onClick={() => window.location.href = PROFILE_CONTRACTOR} />
          </div>
      </Dialog>
        <SideBar linksArray={menuContractor} showButton={false} />
        <div className="Main-center">
          <div className="Main-head flex align-items-center gap-3">
            <HandleGoBack />
            <h3 className="text-base capitalize">{title}</h3>
          </div>
          <div className="Main-mainCenter">
            <Suspense fallback={"..."}>{children}</Suspense>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default LayoutContractor;
