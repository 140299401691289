import React, { useContext, useRef, useState } from "react";
import { BlockAuthSocial } from "../../BlockAuthSocial/BlockAuthSocial";

import "../Auth.scss";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../services/AuthContext";
import { validInputText } from "../../ControlField/controlField";
import { useNavigate } from "react-router-dom";
import { HOME_SCREEN_PAGE, PROJECT_PUBLISH, REGISTER, VERIFY_EMAIL } from "../../../constant/constant";
import { Toast } from "primereact/toast";
import showPassword from "../../ShowPwd/showPassword";
import { jwtDecode } from "jwt-decode";
import { showToast } from "../../../utils/toast";

const LoginComponent = ({ setAuthIndex, nb }) => {
  const { t } = useTranslation();
  const toast = useRef(null);
  const navigate = useNavigate();
  const { loginUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    validInputText(email, "email_error", "email");

    if (validInputText(email, "email_error", "email")) {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      
      try {
        const res = await loginUser(formData);
        console.log(res);
        if (res) {
          if (res.status === 204) {
            showToast(toast, "error", t("Login.connectionFailedPleaseTryAgain"));
          } else if (res.status === 200) {
            const data = res.data;
            const decoded = jwtDecode(data.accessToken);
            const userDetail = decoded.user;
  
            if (data.typeAccount === "clientAuth") {
              localStorage.setItem("userId", userDetail.id);
            }
  
            localStorage.setItem("accessToken", data.accessToken);
  
            if (userDetail.userRole === "contractor") {
              window.location.href = PROJECT_PUBLISH;
            } else {
              window.location.href = HOME_SCREEN_PAGE;
            }
          }
        }
        
      } catch (error) {
        showToast(toast, "error", t("Login.connectionFailedPleaseTryAgain"));
        console.log("Une erreur est survenue lors de l'inscription par google :\n", error);
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <h1 className="FormAuth-h1"> {t("Login.login")}</h1>
      <label className="Field">
        <input
          type="email"
          placeholder={t("common.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <span className="Field-error" id="email_error">
          {" "}
        </span>
      </label>
      <label className="Field">
        {showPassword(".pwd")}
        <input
          className="pwd"
          type="password"
          placeholder={t("common.password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className="Field-error" id="passwd_error">
          {" "}
        </span>
      </label>
      <a className="FormAuth-pwdForgot mb-3" href={VERIFY_EMAIL}>
        {t("Login.iForgotMyPassword")}
      </a>
      <button type="button" className="FormAuth-btnSubmit" onClick={handleSubmit}>
        {t("Login.signIn")}
      </button>
      <BlockAuthSocial />
      <div className="FooterForm Dflex Dflex-col Dflex-alignCenter">
        <p className="FooterForm-p FooterForm-or">
          {t("common.or")}
          <button
            className="FooterForm-btnAuth FooterForm-btnReg"
            type="button"
            onClick={() => (nb === 1 ? setAuthIndex(0) : navigate(REGISTER))}
          >
            {t("common.register")}
          </button>
        </p>
        <div className="FooterForm-linksTerm Dflex Dflex-alignCenter">
          <a href="pol">{t("common.termsAndConditions")}</a>&nbsp;|&nbsp;
          <a href="pol">{t("common.privacyPolicy")}</a>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
