import axios from "axios";
import { API_URL } from "../constant/constant";

const axiosInstance = axios.create({
  baseURL: API_URL, // Mettez votre URL de backend
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["user-id"] = userId;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
