import React from "react";

import "./HandleGoBack.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const HandleGoBack = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Revenir à la page précédente
  };

  return (
    <Button
      className="HandleGoBack"
      icon="pi pi-arrow-left"
      onClick={handleGoBack}
      rounded
      outlined
      raised
      severity="secondary"
      aria-label="Search"
    />
  );
};

export default HandleGoBack;
