function animeSpan(id) {
  const span = document.getElementById(id);

  span.style.animation = "dongle 1s";
  setTimeout(() => {
    span.style.animation = "none";
  }, 1000);
}

// function qui permet de vérifier la validité des champs
export function validInputText(value, id_error, type) {
  const regex = /^[a-zA-ZàéèêçïÏ\s_-]*$/;
  const regex2 = /^[a-zA-ZàéèêïÏç_.\-'`"()??\s]*$/;
  const regexTel2 = /^[0-9]*$/;
  const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
  const regexCodePostale = /^[a-zA-Z0-9]*$/;
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&+=!]).*$/;
  const regexAn = /^[0-9]*$/;
  const regexArea = /^[a-zA-ZàéèêïÏ0-9_.'\s]*$/;
  const error = document.getElementById(id_error);
  const regexPrice = /^[0-9.,]*$/;

  if (type === "text") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
    } else if (value.length < 3) {
      error.textContent = "Ce champs est trop court...";
      return false;
    } else if (regex.test(value) === false) {
      error.textContent = "format invalide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "text2") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
    } else if (value.length < 6) {
      error.textContent = "Ce champs est trop court...";
      return false;
    } else if (regex2.test(value) === false) {
      error.textContent = "format invalide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "number") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
    } else if (regexAn.test(value) === false) {
      error.textContent = "format invalide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "tel") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
      return false;
    } else if (value.length < 8) {
      error.textContent = "le numéro est trop court...";
      return false;
    } else if (regexTel2.test(value) === false) {
      error.textContent = "format du numéro invalide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "email") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
      return false;
    } else if (regexEmail.test(value) === false) {
      error.textContent = "format de l'email est invalide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "date") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "pwd") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
      return false;
    } else if (value.length < 6) {
      error.textContent = "Mot de passe trop court...";
      return false;
    } else if (passwordRegex.test(value) === false) {
      error.textContent = "Mot de passe trop faible. (ex: M@x992##)";
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "alpha") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
      return false;
    } else if (value.length < 12) {
      error.textContent = "description trop court...";
      return false;
    } else if (regex.test(value) === false) {
      error.textContent = "la description doit ètre des caractère alphanumérique...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "codeP") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
      return false;
    } else if (value.length < 3) {
      error.textContent = "code postale trop court...";
      return false;
    } else if (regexCodePostale.test(value) === false) {
      error.textContent = "le format du code postale est incorrect...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "adresse") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
      return false;
    } else if (value.length < 6) {
      error.textContent = "adresse trop court...";
      return false;
    } else if (regex.test(value) === false) {
      error.textContent = "...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "price") {
    if (!value) {
      error.textContent = "Ce champ est vide...";
      return false;
    } else if (regexPrice.test(value) === false) {
      error.textContent = "Format invalide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "textArea") {
    if (!value) {
      error.textContent = "le champs est vide...";
      return false;
    } else if (value.length < 6) {
      error.textContent = "message trop court...";
      return false;
    } else if (regexArea.test(value) === false) {
      error.textContent = "Format invalide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  } else if (type === "codeOtp") {
    if (!value) {
      error.textContent = "Veuillez entrer le code reçu par mail";
      return false;
    } else if (value.length !== 4) {
      error.textContent = "le code est 4 ciffre...";
      return false;
    } else if (regexArea.test(value) === false) {
      error.textContent = "Format invalide...";
      return false;
    } else {
      error.textContent = "";
      return true;
    }
  }
}

// function qui permet de voir mot de passe en clair
export const changeType = (e, id) => {
  e.preventDefault();
  const pwd = document.getElementById(id);

  if (pwd.type === "password") {
    pwd.type = "text";
  } else {
    pwd.type = "password";
  }
};
