import React, { useEffect } from "react";
import AppRouter from "./routing";
import { AuthProvider } from "./services/AuthContext";

import { PrimeReactProvider } from "primereact/api";
import { GlobalProvider } from "./services/GlobalContext";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/fr";
import "dayjs/locale/en";

dayjs.extend(localizedFormat);
dayjs.extend(duration);

function App() {
  return (
    <PrimeReactProvider>
      <AuthProvider>
        <GlobalProvider>
          <AppRouter />
        </GlobalProvider>
      </AuthProvider>
    </PrimeReactProvider>
  );
}

export default App;
