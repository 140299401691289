import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import "flag-icon-css/css/flag-icons.css";

import "./SelectCountryField.scss";
import { countries } from "../countryList/countrylist";

const SelectCountryField = ({ country, setCountry, showLabel }) => {
  const [countrys, setCountrys] = useState(country);
  const [code, setCode] = useState("bj");
  const [searchField, setSearchField] = useState("");
  const [opens, isOpen] = useState(false);

  const selectedCountry = (name, code, dialCode) => {
    setCountrys(name);
    setCountry(name);
    setCode(code.toLowerCase());
    isOpen(!opens);
  };

  const open = { display: "block" };
  const close = { display: "none" };

  return (
    <div className="select-country">
      <div className="selected" onClick={() => isOpen(!opens)} onKeyUp={() => isOpen(!opens)}>
        <div className="selected-item-options">
          <>
            <i className={`flag-icon flag-icon-${code}`} />
            {showLabel ? (
              <span>
                {" "}
                <input type="text" value={countrys} />{" "}
              </span>
            ) : (
              ""
            )}
          </>
        </div>
        <IoMdArrowDropdown className="arrow" />
      </div>
      <div className="options-select" style={opens ? open : close}>
        <div className="search-country-div">
          <FiSearch />
          <input
            type="text"
            name="country"
            placeholder="Rechercher..."
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          />
        </div>
        {countries.map((list, index) => {
          if (!searchField) {
            return (
              <li
                className="option"
                onClick={() => selectedCountry(list.name, list.code, list.dial_code)}
                onKeyUp={() => selectedCountry(list.name, list.code, list.dial_code)}
                key={index.toString()}
              >
                <i className={`flag-icon flag-icon-${list.code.toLowerCase()}`} />
                <span>{list.name}</span>{' '}
                <b>{list.dial_code}</b>
              </li>
            );
          } else {
            const a = list.name.toLowerCase();
            const b = searchField.toLowerCase();
            if (a.includes(b)) {
              return (
                <li
                  className="option"
                  onClick={() => selectedCountry(list.name, list.code, list.dial_code)}
                  onKeyUp={() => selectedCountry(list.name, list.code, list.dial_code)}
                  key={index.toString()}
                >
                  <i className={`flag-icon flag-icon-${list.code.toLowerCase()}`} />
                  <span> {list.name} </span>
                </li>
              );
            }
          }
        })}
      </div>
    </div>
  );
};

export default SelectCountryField;
