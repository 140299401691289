import React from "react";
import { useTranslation } from "react-i18next";
import Auth from "../Auth";

import "../Auth.scss";

const ConfirmAccount = ({ isOpenDialogAuth }) => {
  const { t } = useTranslation();

  return (
    <Auth isOpenDialogAuth={isOpenDialogAuth}>
      <h1 className="FormAuth-h1">{t("confirmAuth.accountActivation")}</h1>
      <p className="FormAuth-subTitle">
        {t("confirmAuth.EnterThe5digitCodeYouReceivedByEmailAtTheAddressYouProvided")}
      </p>
      <label className="Field">
        <input type="text" placeholder="00000" />
        <span className="Field-error"> </span>
      </label>
      <button type="button" className="FormAuth-btnSubmit">
        {t("confirmAuth.activateMyAccount")}
      </button>
    </Auth>
  );
};

export default ConfirmAccount;
