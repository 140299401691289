import React, { useContext, useRef, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { InputMask } from "primereact/inputmask";

import "./BlockAuthSocial.scss";

import google from "../../assets/images/google.webp";
import instagram from "../../assets/images/instagram.webp";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { RadioButton } from "../RadioButton/RadioButton";
import SelectCountryField from "../SelectCountryField/SelectCountryField";
import AuthContext from "../../services/AuthContext";
import { Toast } from "primereact/toast";
import { APP_ID_INSTAGRAM, FRONTEND_URL, HOME_SCREEN_PAGE, PROJECT_PUBLISH } from "../../constant/constant";
import { removeSpaces } from "../removeSpace/removeSpace";
import { InputText } from "primereact/inputtext";
import { keepOnlyNumbers } from "../keepOnlyNumbers/keepOnlyNumbers";
import { showToast } from "../../utils/toast";
import { jwtDecode } from "jwt-decode";

export const BlockAuthSocial = () => {
  const { t, i18n } = useTranslation();
  const toast = useRef(null);
  const {
    loginUser,
    googleLogin,
    visible,
    setVisible,
    user,
    profile,
    setProfile,
    setUser,
    verifyEmailLoginClientAuth,
  } = useContext(AuthContext);
  const [role, setRole] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [country, setCountry] = useState("Benin");
  const [selectedPDFs, setSelectedPDFs] = useState([]);

  const handlePDFChange = (e) => {
    const files = e.target.files;
    if (files) {
      const pdfFiles = Array.from(files).filter((file) => {
        return file.type === "application/pdf";
      });

      if (pdfFiles.length === 0) {
        alert("Veuillez sélectionner des fichiers PDF");
        return;
      }
      setSelectedPDFs([...selectedPDFs, ...pdfFiles]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    //formData.append("email", profile.email);
    formData.append("fullname", profile.name);
    //formData.append("picture", profile.picture);
    formData.append("id", profile.id);
    formData.append("country", country);
    formData.append("phone", cellPhone ? removeSpaces(cellPhone) : null);
    formData.append("userRole", role);
    formData.append("lang", i18n.language);
    formData.append("social_auth_token", user.access_token);
    formData.append("expires_in", user.expires_in);
    if (selectedPDFs.length > 0) {
      for (const file of selectedPDFs) {
        formData.append("pdfs", file);
      }
    }

    try {
      const res = await loginUser(formData);
      console.log(res);
      if (res) {
        if (res.status === 204) {
          showToast(toast, "error", t("Login.connectionFailedPleaseTryAgain"));
        } else if (res.status === 200) {
          const data = res.data;
          const decoded = jwtDecode (data.accessToken);
          const userDetail = decoded.user;

          if (data.typeAccount === "clientAuth") {
            localStorage.setItem("userId", userDetail.id);
          }

          localStorage.setItem("accessToken", data.accessToken);

          if (userDetail.userRole === "contractor") {
            window.location.href = PROJECT_PUBLISH;
          } else {
            window.location.href = HOME_SCREEN_PAGE;
          }
        }
      }
      
    } catch (error) {
      showToast(toast, "error", t("Login.connectionFailedPleaseTryAgain"));
      console.log("Une erreur est survenue lors de l'inscription par google :\n", error);
    }

  };

  const renderButtonSubmit = () => {
    if (role === t("common.buyer") || selectedPDFs.length > 0) {
      return (
        <button type="button" className="FormAuth-btnSubmit" onClick={handleSubmit}>
          {t("Register.signUp")}
        </button>
      );
    } else if (role === t("common.contractor")) {
      return (
        <button type="button" className="FormAuth-btnSubmit">
          <input
            className="FormAuth-inputFile"
            type="file"
            accept=".pdf"
            multiple
            onChange={handlePDFChange}
          />
          {t("Register.verifyUploadDocument")}
        </button>
      );
    }
  };

  const loginIntagram = () => {
    const url = `https://api.instagram.com/oauth/authorize?force_authentication=1&client_id=${APP_ID_INSTAGRAM}&redirect_uri=${FRONTEND_URL}&scope=user_profile,user_media&state=1&response_type=code`;
    window.open(url, "_blank").focus();
  };

  const handleFacebookLoginLoginResponse = async (res) => {
    if (res) {
      setProfile({
        name: res.name,
        id: res.id,
        expire_time: res.data_access_expiration_time,
      });
      setUser({ access_token: res.accessToken });

      verifyEmailLoginClientAuth(res.id, res.accessToken, res.expiresIn, setVisible);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        headerClassName="pl-3 pt-3"
        contentClassName="flex flex-column align-items-center"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "500px" }}
      >
        <form className="BlockAuthSocial-form FormAuthSocial">
          <h1 className="FormAuthSocial-h1 text-xl mb-5 text-center">
            {t("Register.completeYourRegistrationDetails")}{" "}
          </h1>
          <div className="Role flex align-items-center mb-5">
            <span className="Role-label">{t("Register.iAm")}</span>
            <RadioButton label={t("common.buyer")} name={"role"} setRole={setRole} />
            <RadioButton label={t("common.contractor")} name={"role"} setRole={setRole} />
          </div>
          <div className="PhoneField">
            <div className="PhoneField-blockInput Dflex">
              <SelectCountryField setCountry={setCountry} country={country} showLabel={false} />
              <InputText
                className="h-1rem"
                value={keepOnlyNumbers(cellPhone)}
                onChange={(e) => {
                  setCellPhone(e.target.value);
                }}
                placeholder="229 00000000"
              />
            </div>
            <span className="Field-error" id="phone_error">
              {" "}
            </span>
          </div>
          <button type="button" className="FormAuth-btnSubmit" onClick={handleSubmit}>
            {t("Register.signUp")}
          </button>
        </form>
      </Dialog>
      <div className="BlockAuthSocial Dflex Dflex-alignCenter Dflex-col">
        {/* <span className="BlockAuthSocial-title">Login with social media</span> */}
        <div className="BlockAuthSocial-groupBtn Dflex">
          {/* <button type="button" onClick={googleLogin}>
            <img src={google} alt="" />
          </button> */}
          {/* <FacebookLogin
            appId="914099123465314"
            fields="email,name"
            callback={handleFacebookLoginLoginResponse}
            render={(renderProps) => (
              <div className="login-social-item login-social-item--facebook">
                <img
                  onClick={renderProps.onClick}
                  className="login-social-item__image"
                  src={"https://findicons.com/files/icons/2830/clean_social_icons/250/facebook.png"}
                  alt=""
                  width={32}
                  height={32}
                />
              </div>
            )}
          />
          <button type="button" onClick={loginIntagram}>
            <img src={instagram} alt="" />
          </button> */}
        </div>
      </div>
    </>
  );
};
