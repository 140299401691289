import React, { useContext, useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { FiMenu } from "react-icons/fi";

import "./TopBar.scss";
import i18n from "../../i18n";
import { GroupBtnAuth } from "../GroupBtnAuth/GroupBtnAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { HOME_BUYER_PAGE, PROJECT_EXISTING_PAGE } from "../../constant/constant";
import AuthContext from "../../services/AuthContext";
import GlobalContext from "../../services/GlobalContext";

export const TopBar = () => {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const { isMobile } = useContext(GlobalContext);
  const location = useLocation();
  const [openLangBox, isOpenLangBox] = useState(false);
  const [lang, setLang] = useState(i18n.language);
  const [showMenuIcon, isShowMenuIcon] = useState(false);

  useEffect(() => {
    const url = location.pathname;

    if (token) {
      selectedRole(token.userRole);
    }

    if (url.includes("buyer") || url.includes("buyers") || url.includes("contractor")) {
      isShowMenuIcon(true);
    }

  }, []);

  const open = { display: "flex" };
  const close = { display: "none" };

  const changeLanguage = (lng) => {
    localStorage.setItem("langSite", lng);
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  const openSideBar = () => {
    const sidebar = document.querySelector(".Sidebar");
    if (sidebar) {
      sidebar.style.display = "block";
    }
  };

  const selectedRole = (role) => {
    const allRoles = document.querySelectorAll(".TopBar-role");
    console.log(role);
    for (const roles of allRoles) {
      console.log(roles);
      if (roles.innerText.toLowerCase() === role.toLowerCase()) {
        //roles.classList.add("selected");
      } else {
        //roles.classList.remove("selected");
      }
    }
  };

  return (
    <div className={`TopBar PaddingLeftRight flex align-items-center ${isMobile ? "justify-content-between":"justify-content-end"} w-full`}>
      <ul className="TopBar-left flex flex-row">
        {showMenuIcon && <FiMenu className="TopBar-sidebarIcon" onClick={openSideBar} />}
      </ul>
      <ul className="TopBar-right Dflex Dflex-alignCenter">
        <div className="SelectLang">
          <button
            type="button"
            className="SelectLang-langSelected"
            onClick={() => isOpenLangBox(!openLangBox)}
          >
            <label className="SelectLang-lang">{lang}</label>
            <FiChevronDown />
          </button>
          <div className="SelectLang-items" style={openLangBox ? open : close}>
            <button type="button" onClick={() => changeLanguage("fr")}>
              fr
            </button>
            <button type="button" onClick={() => changeLanguage("en")}>
              en
            </button>
          </div>
        </div>
        { !isMobile ? <GroupBtnAuth class="ml-6" /> : "" }
      </ul>
    </div>
  );
};

{/* <li
          className="TopBar-role"
          onClick={() => {
            localStorage.setItem("role", t("common.buyer"));
            selectedRole(t("common.buyer"));
            navigate(HOME_BUYER_PAGE);
          }}
          onKeyUp={() => {
            localStorage.setItem("role", t("common.buyer"));
            selectedRole(t("common.buyer"));
            navigate(HOME_BUYER_PAGE);
          }}
        >
          {t("common.buyer")}
        </li>
        <li
          className="TopBar-role"
          onClick={() => {
            localStorage.setItem("role", t("common.contractor"));
            selectedRole(t("common.contractor"));
            navigate(PROJECT_EXISTING_PAGE);
          }}
          onKeyUp={() => {
            localStorage.setItem("role", t("common.contractor"));
            selectedRole(t("common.contractor"));
            navigate(PROJECT_EXISTING_PAGE);
          }}
        >
          {t("common.contractor")}
        </li> */}