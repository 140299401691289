import React from "react";

import logo from "../assets/images/logo.webp";

import "./Layout.scss";

const LayoutAuth = ({ children }) => {
  return (
    <div className="Layout">
      <header className="Header w-full">
        <div className="Header-menuBar PaddingLeftRight w-full TopBar Dflex Dflex-alignCenter Dflex-justifySpaceBetween">
          <li className="Header-linklogo Dflex Dflex-alignEnd">
            <a href="/" className="Dflex Dflex-alignEnd">
              <img src={logo} alt="" /> <span>E-CON</span>
            </a>
          </li>
        </div>
      </header>
      <div className="Layout-body Layout-bodyAuth">{children}</div>
    </div>
  );
};

export default LayoutAuth;
