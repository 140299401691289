import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";

import logo from "../assets/images/logo.webp";
import "./Layout.scss";
import {
  BOOKING_ADMIN_PAGE,
  BUYERS_ADMIN_PAGE,
  CONTRACTOR_ADMIN_PAGE,
  HOME_SCREEN_PAGE,
  OFFERS_ADMIN_PAGE,
} from "../constant/constant";
import { TopBar } from "../components/TopBar/TopBar";
import { NavBar } from "../components/NavBar/NavBar";
import { useTranslation } from "react-i18next";

const LayoutAdmin = ({ children }) => {
  const { t } = useTranslation();

  const linksHomeSreen = [
    { path: BUYERS_ADMIN_PAGE, label: t("NavBar.buyers") },
    { path: CONTRACTOR_ADMIN_PAGE, label: t("NavBar.contractors") },
    { path: BOOKING_ADMIN_PAGE, label: t("NavBar.booking") },
    { path: OFFERS_ADMIN_PAGE, label: t("NavBar.offers") },
    { path: HOME_SCREEN_PAGE, label: t("NavBar.content") },
  ];

  const openNavBar = () => {
    const navbar = document.querySelector(".NavBar");
    if (navbar.style.display === "none") {
      navbar.style.display = "block";
    } else {
      navbar.style.display = "none";
    }
  };

  return (
    <div className="Layout">
      <header className="Header w-full">
        <TopBar />
        <div className="Header-menuBar PaddingLeftRight w-full TopBar Dflex Dflex-alignCenter Dflex-justifySpaceBetween">
          <li className="Header-linklogo Dflex Dflex-alignEnd">
            <a href="/" className="Dflex Dflex-alignEnd">
              <img src={logo} alt="" /> <span>E-CON</span>
            </a>
            <AiOutlineMenuUnfold className="Header-sideBarIcon" onClick={openNavBar} />
          </li>
          <NavBar linksArray={linksHomeSreen} />
          <button type="button" className="Header-btnEstimate">
            <GiHamburgerMenu />
          </button>
        </div>
      </header>
      <div className="Layout-body">{children}</div>
      
    </div>
  );
};

export default LayoutAdmin;
