import React from "react";
import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { LOGIN } from "./constant/constant";
import AuthContext from "./services/AuthContext";

const PrivateRouteContractor = ({ children }) => {
  const { token } = useContext(AuthContext);
  console.log(token);
  if (token) {
    const role = token.userRole.toLowerCase();
    if (role === "contractor" || role === "entrepreneur") {
      return children;
    }
  }
  return <Navigate to={LOGIN} replace />;
};

export default PrivateRouteContractor;
