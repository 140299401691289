import axios from "../config/axiosConfig";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  API_URL,
  BUYER_NOTIFICATION_NEWTASK_PAGE,
  PROJECT_SAVE,
  YOUR_BIDS_PAGE,
  config,
} from "../constant/constant";
import { useTranslation } from "react-i18next";
import AuthContext from "./AuthContext";

const GlobalContext = createContext();
export default GlobalContext;

export const GlobalProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { token, logoutUser } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  const disconnect = (error) => {
    console.log(error);
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.msg === "token invalid"
    ) {
      logoutUser();
    }

    if (error.message && error.message === "Network Error") {
      logoutUser();
    }
    return error;
  };

  // get profile user
  const [userProfile, setUserProfile] = useState();
  const getUserProfile = async (id) => {
    try {
      await axios.get(`${API_URL}account/get-profile/${id}`).then((res) => {
        if (res.status === 200) {
          const data = res.data;
          setUserProfile(data);

          if (data.userRole === "Entrepreneur" || data.userRole === "Contractor") {
            getProjectByRegion(data.country);
          } else {
            getContractorByCountry(data.country);
          }
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  // update profile
  const updateUserProfile = async (userId, formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(`${API_URL}account/update-profile/${userId}`, formData);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  // operation projet
  const [projetSave, setProjetSave] = useState([]);
  const [projectById, setProjectById] = useState();
  const [projectByCountry, setProjectByCountry] = useState([]);

  const getProjectSave = async () => {
    try {
      await axios.get(`${API_URL}project/get-project-save`).then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setProjetSave(response.data);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  const createProject = async (
    projectName,
    projectDesc,
    houseType,
    plotSize,
    noBedroom,
    noBathroom,
    noSalon,
    noKitchen,
    roomType,
    colorExt,
    colorInt,
    otherOption,
    cityDistrictBuilding,
    buildingCountry,
    showToast,
  ) => {
    try {
      await axios
        .post(
          `${API_URL}projet/create`,
          {
            projectName: projectName,
            projectDesc: projectDesc,
            typeHouse: typeof houseType === "object" ? houseType.name : houseType,
            plotSize: plotSize,
            noBedroom: noBedroom,
            noBathroom: noBathroom,
            typeRoom: roomType,
            colorExt: colorExt,
            colorInt: colorInt,
            otherOption: otherOption,
            noSalon: noSalon,
            userId: token.id,
            cityDistrictBuilding: cityDistrictBuilding,
            noKitchen: noKitchen,
            buildingCountry: buildingCountry,
          },
          config,
        )
        .then((response) => {
          if (response.status === 201) {
            showToast(t("AddProject.projectSuccessfullyCreated"));
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            showToast(t("AddProject.failedProjectCreation"));
          }
        });
      //root@econstructiv
    } catch (error) {
      disconnect(error);
    }
  };

  const publishProject = async (projectId, showToast) => {
    try {
      await axios
        .put(`${API_URL}project/published/${projectId}`, {
          subject: t("common.notificationOnEconstructiv"),
          text: t(
            "common.aNewProjectHasBeenPublishedInYourRegionGoToYourUserAreaForMoreInformation",
          ),
        })
        .then((response) => {
          if (response.status === 200) {
            showToast(t("project.successfullyPublishedProject"));
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            showToast(t("project.failureToPublishTheProject"));
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  const unPublishedProject = async (projectId, showToast) => {
    try {
      await axios.put(`${API_URL}project/no-published/${projectId}`).then((response) => {
        if (response.status === 200) {
          showToast(t("project.thisProjectHasBeenWithdrawnFromThePublishedProjects"));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          showToast(
            t("project.errorThisProjectCouldNotBeRemovedFromThePublishedProjectsPleaseTryAgain"),
          );
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  // Task
  const [task, setTask] = useState([]);
  const [taskById, setTaskById] = useState();

  const getTask = async (projetId) => {
    try {
      await axios.get(`${API_URL}task/get/${projetId}`).then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setTask(response.data);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  const getTaskById = async (taskId) => {
    try {
      await axios.get(`${API_URL}task/get-by-id/${taskId}`).then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setTaskById(response.data);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  const addTask = async (
    projetId,
    title,
    description,
    duration,
    startDate,
    budget,
    project,
    accept,
    clearField,
  ) => {
    try {
      await axios
        .post(`${API_URL}task/add`, {
          projetId: projetId,
          title: title,
          description: description,
          duration: duration,
          expectedStartDate: startDate,
          budget: budget,
          subject: t("common.notificationOnEconstructiv"),
          text: t(
            "common.aNewTaskHasBeenCreatedForThe{{project}}ProjectGoToYourUserAreaForMoreInformation",
            {
              task: title,
              project: project,
            },
          ),
        })
        .then((response) => {
          if (response.status === 201) {
            clearField();
            accept(t("common.taskSuccessfullyAdded"));

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            accept(t("common.addFailed"));
          }
        });
    } catch (error) {
      accept(t("common.unknownErrorPleaseTryAgain"));
      disconnect(error);
    }
  };

  const updateTask = async (
    taskId,
    title,
    description,
    duration,
    startDate,
    budget,
    project,
    accept,
    clearField,
  ) => {
    try {
      await axios
        .put(`${API_URL}task/update/${taskId}`, {
          title: title,
          description: description,
          duration: duration,
          expectedStartDate: startDate,
          budget: budget,
          subject: t("common.notificationOnEconstructiv"),
          text: t(
            "common.theNewTask{{task}}OfTheProject{{project}}HasBeenUpdatedGoToYourUserAreaForMoreInformation",
            {
              task: title,
              project: project,
            },
          ),
        })
        .then((response) => {
          if (response.status === 200) {
            clearField();
            accept(t("common.theUpdateHasBeenCompletedSuccessfully"));

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            accept(t("common.updateFailed"));
          }
        });
    } catch (error) {
      accept(t("common.unknownErrorPleaseTryAgain"));
      disconnect(error);
    }
  };

  const updateStatusTask = async (taskId, formData, accept, clearField, setVisible) => {
    setVisible(true);
    try {
      const data = await axios.put(`${API_URL}task/update-status/${taskId}`, formData);
      if (data.status === 200) {
        setVisible(false);
        clearField();
        accept(t("common.theUpdateHasBeenCompletedSuccessfully"));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        accept(t("common.updateFailed"));
      }
    } catch (error) {
      disconnect(error);
    }
  };

  const deleteTask = async (taskId, setVisible2, accept) => {
    setVisible2(false);
    try {
      await axios.delete(`${API_URL}task/delete/${taskId}`).then((response) => {
        if (response.status === 200) {
          window.location.reload();
          accept(t("common.deletionCompletedsuccessfully"));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          accept(t("common.deletionFailed"));
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  const approvedTask = async (taskId, project, task, showToast) => {
    try {
      await axios
        .put(`${API_URL}task/approved/${taskId}`, {
          subject: t("common.notificationOnEconstructiv"),
          text: t("common.the{{task}}TaskInThe{{project}}ProjectHasBeenApproved", {
            task: task,
            project: project,
          }),
        })
        .then((res) => {
          if (res.status === 200) {
            showToast(t("Task.taskSuccessfullyApproved"));
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            showToast(t("Task.taskValidationFailedPleaseTryAgain"));
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  const rejectedTask = async (taskId, project, task, showToast) => {
    try {
      await axios
        .put(`${API_URL}task/rejected/${taskId}`, {
          subject: t("common.notificationOnEconstructiv"),
          text: t("common.the{{task}}TaskInThe{{project}}ProjectHasBeenRejected", {
            task: task,
            project: project,
          }),
        })
        .then((res) => {
          if (res.status === 200) {
            showToast(t("Task.taskSuccessfullyRejected"));
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            showToast(t("Task.jobRejectionFailedPleaseTryAgain"));
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  //contractor
  const [contractorByCountry, setContractorByCountry] = useState([]);

  const getContractorByCountry = async (country) => {
    try {
      await axios
        .get(`${API_URL}contractor/get-contractor-by-country/${country}`)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setContractorByCountry(response.data);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // bid
  const [bidsByBuyer, setBidsByBuyer] = useState([]);
  const [bidsByContractor, setBidsByContractor] = useState([]);
  const [bidById, setBidById] = useState([]);

  const getBidByBuyer = async (buyerId) => {
    try {
      await axios.get(`${API_URL}bid/get-bid-by-buyer/${buyerId}`).then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setBidsByBuyer(response.data);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  const getBidContractor = async (contractorId) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await axios.get(`${API_URL}bid/get-bid-by-contractor/${contractorId}`);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  // get bid by id
  const getBidById = async (bidId) => {
    try {
      await axios.get(`${API_URL}bid/get-by-id/${bidId}`).then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setBidById(res.data);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  // get notification
  const [dataNotificationContractor, setDataNotificationContractor] = useState([]);
  const [dataNotificationBuyer, setDataNotificationBuyer] = useState([]);

  const getNotifications = async (userId) => {
    try {
      await axios.get(`${API_URL}account/notifications/${userId}`).then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          const dataNewTask = res.data.tasksCreated;
          const dataTaskInProgress = res.data.tasksInProgress;
          const dataTaskCompleted = res.data.tasksCompleted;
          const dataNewBid = res.data.newOffer;

          const d11 =
            dataNewTask.length > 0 &&
            dataNewTask.filter((item) => item.Project && item.Project.User.id === token.id);
          const d12 =
            dataTaskInProgress.length > 0 &&
            dataTaskInProgress.filter((item) => item.Project && item.Project.User.id === token.id);
          const d13 =
            dataTaskCompleted.length > 0 &&
            dataTaskCompleted.filter((item) => item.Project && item.Project.User.id === token.id);
          const d14 =
            dataNewBid.length > 0 &&
            dataNewBid.filter((item) => item.Project && item.Project.User.id === token.id);

          const d1 =
            d11.length > 0
              ? d11.map((list) => {
                  return {
                    title: t("common.newTask"),
                    text: t("common.the{{task}}TaskHasBeenAddedToThe{{projectName}}project", {
                      task: list.task,
                      projectName: list.Project ? list.Project.projectName : "",
                    }),
                    url: `/buyer/task/details?taskId=${list.taskId}`,
                    newNotify: list.creationTaskNotifyOwner,
                    typeNotify: 1,
                    createdAt: list.createdAt,
                  };
                })
              : [];
          console.log("d1==", d1);

          const d2 =
            d12.length > 0
              ? d12.map((list) => {
                  return {
                    title: t("common.taskInProgress"),
                    text: t("common.task{{task}}ofProject{{projectName}}isInProgress", {
                      task: list.task,
                      projectName: list.Project ? list.Project.projectName : "",
                    }),
                    url: `/buyer/task/details?taskId=${list.taskId}`,
                    newNotify: list.courtyardWorkAsSeenByTheOwner,
                    typeNotify: 2,
                    createdAt: list.createdAt,
                  };
                })
              : [];

          const d3 =
            d13.length > 0
              ? d13.map((list) => {
                  return {
                    title: t("common.completedTask"),
                    text: t("common.task{{task}}ofProject{{projectName}}isFinished", {
                      task: list.task,
                      projectName: list.Project ? list.Project.projectName : "",
                    }),
                    url: `/buyer/task/details?taskId=${list.taskId}`,
                    newNotify: list.finishTaskNotifyOwner,
                    typeNotify: 3,
                    createdAt: list.createdAt,
                  };
                })
              : [];

          const d4 =
            d14.length > 0
              ? d14.map((list) => {
                  return {
                    title: t("common.offer"),
                    text: t("common.aNewBidWasSubmitted{{projectName}}", {
                      projectName: list.Project ? list.Project.projectName : "",
                    }),
                    url: `/buyers/bid/details?bidId=${list.bidId}`,
                    newNotify: list.newBidSeenByOwner,
                    createdAt: list.createdAt,
                  };
                })
              : [];

          const data = [];

          if (d1 && d1.length > 0) {
            data.push(...d1);
          }

          if (d2 && d2.length) {
            data.push(...d2);
          }

          if (d3 && d3.length) {
            data.push(...d3);
          }

          if (d4 && d4.length) {
            data.push(...d4);
          }

          console.log("data=", data);
          setDataNotificationBuyer([...data]);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  const getProjectBidsNotificationContractor = async (country, userId) => {
    try {
      await axios
        .post(`${API_URL}account/notifications-projects-bids`, {
          country: country,
          userId: userId,
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            const data = res.data.projectPublish;
            const dataBidApproved = res.data.bidApproved;
            const dataBidRejected = res.data.bidRejeted;
            const dataTaskApproved = res.data.taskApproved;
            const dataTaskRejected = res.data.taskRejected;

            const n11 =
              dataBidApproved.length > 0 &&
              dataBidApproved.filter(
                (item) =>
                  item.Project &&
                  item.Project.Contractor &&
                  item.Project.Contractor.User.id === token.id,
              );
            const n12 =
              dataBidRejected.length > 0 &&
              dataBidRejected.filter(
                (item) =>
                  item.Project &&
                  item.Project.Contractor &&
                  item.Project.Contractor.User.id === token.id,
              );
            const n13 =
              dataTaskApproved.length > 0 &&
              dataTaskApproved.filter(
                (item) =>
                  item.Project &&
                  item.Project.Contractor &&
                  item.Project.Contractor.User.id === token.id,
              );
            const n14 =
              dataTaskRejected.length > 0 &&
              dataTaskRejected.filter(
                (item) =>
                  item.Project &&
                  item.Project.Contractor &&
                  item.Project.Contractor.User.id === token.id,
              );

            const returnBool = (list) => {
              if (list) {
                if (list.ViewProjects) {
                  const find = list.ViewProjects.find((item) => item.id === token.id);
                  if (find) {
                    return true;
                  } else return false;
                }
              }
            };

            const n1 =
              data.length > 0 &&
              data.map((list) => {
                return {
                  title: list.projectName,
                  text: t("common.toPublishANewBuildingProject", {
                    name: list && list.User && list.User.fullname ? list.User.fullname : "-",
                  }),
                  url: `/contractor/project/details?projectId=${list.projetId}`,
                  list: list,
                  newNotify: returnBool(list),
                  createdAt: list.createdAt,
                };
              });

            const n2 =
              n11.length > 0
                ? n11.map((list) => {
                    return {
                      title: t("OfferSubmit.offerApproved"),
                      text: t("OfferSubmit.hasApprovedYourBidForItsProject", {
                        name:
                          list && list.Project && list.Project.User && list.Project.User.fullname
                            ? list.Project.User.fullname
                            : "-",
                      }),
                      url: `/contractor/bid/details?bidId=${list.bidId}`,
                      list: list,
                      typeBid: "approved",
                      newNotify: list.approvedOfferView,
                      createdAt: list.createdAt,
                    };
                  })
                : [];

            const n3 =
              n12.length > 0
                ? n12.map((list) => {
                    return {
                      title: t("OfferSubmit.offerRejected"),
                      text: t("OfferSubmit.hasRejectedYourBidForItsProject", {
                        name:
                          list && list.Project && list.Project.User && list.Project.User.fullname
                            ? list.Project.User.fullname
                            : "-",
                      }),
                      url: `/contractor/bid/details?bidId=${list.bidId}`,
                      list: list,
                      typeBid: "rejected",
                      newNotify: list.offerRejectedView,
                      createdAt: list.createdAt,
                    };
                  })
                : [];

            const n4 =
              n13.length > 0
                ? n13.map((list) => {
                    return {
                      title: t("common.approvedTask"),
                      text: t("common.The{{task}}TaskHasBeenApproved", {
                        task: list?.title,
                      }),
                      url: `/contractor/task/details?taskId=${list.taskId}`,
                      list: list,
                      typeNotify: 4,
                      newNotify: list.approvedTaskViewed,
                      createdAt: list.dateApprovedOrRejected,
                    };
                  })
                : [];

            const n5 =
              n14.length > 0
                ? n14.map((list) => {
                    return {
                      title: t("common.rejectedTask"),
                      text: t("common.The{{task}}TaskHasBeenRejected", {
                        task: list?.title,
                      }),
                      url: `/contractor/task/details?taskId=${list.taskId}`,
                      list: list,
                      typeNotify: 5,
                      newNotify: list.rejectedTaskViewed,
                      createdAt: list.dateApprovedOrRejected,
                    };
                  })
                : [];

            const data2 = [];

            if (n1 && n1.length > 0) {
              data2.push(...n1);
            }

            if (n2 && n2.length > 0) {
              data2.push(...n2);
            }

            if (n3 && n3.length > 0) {
              data2.push(...n3);
            }

            if (n4 && n4.length > 0) {
              data2.push(...n4);
            }

            if (n5 && n5.length > 0) {
              data2.push(...n5);
            }
            setDataNotificationContractor([...data2]);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update notification status new tasks
  const updateStatusNotififyNewTask = async (userId, taskId) => {
    console.log(userId, "====", taskId);
    try {
      await axios
        .post(`${API_URL}account/update-notify-new-task`, { userId: userId, taskId: taskId })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            getNotifications(userId);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update notification status tasks in progress
  const updateStatusNotififyTaskInProgress = async (userId, taskId) => {
    try {
      await axios
        .post(`${API_URL}account/update-notify-tasks-in-progress`, {
          userId: userId,
          taskId: taskId,
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            getNotifications(userId);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update notification status tasks completed
  const updateStatusNotififyTaskCompleted = async (userId, taskId) => {
    try {
      await axios
        .post(`${API_URL}account/update-notify-tasks-completed`, {
          userId: userId,
          taskId: taskId,
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            getNotifications(userId);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update notification status task aprrove
  const updateStatusNotifyTaskApproved = async (userId, taskId) => {
    try {
      await axios
        .post(`${API_URL}account/update-notify-task-approved`, { userId: userId, taskId: taskId })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            getProjectBidsNotificationContractor(userProfile?.country, token?.id);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update notification status task rejected
  const updateStatusNotifyTaskRejected = async (userId, taskId) => {
    try {
      await axios
        .post(`${API_URL}account/update-notify-task-rejected`, { userId: userId, taskId: taskId })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            getProjectBidsNotificationContractor(userProfile?.country, token?.id);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update notification status tasks completed
  const updateStatusNotififyNewBid = async (bidId) => {
    try {
      await axios
        .put(`${API_URL}account/update-notify-new-bid/${userProfile ? userProfile.id : 0}`, {
          bidId: bidId,
        })
        .then((res) => {
          if (res.status === 200) {
            getNotifications(token?.id);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update view project by contractor
  const updateStatusViewedProject = async (userId, projectId) => {
    console.log(userId);
    try {
      await axios
        .put(`${API_URL}account/create-view-project/${userId}`, { projectId: projectId })
        .then((res) => {
          if (res.status === 200) {
            getProjectBidsNotificationContractor(userProfile?.country, token?.id);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // set status bid approved
  const updateStatusNotifiyBidApproved = async (bidId) => {
    try {
      await axios.put(`${API_URL}account/update-notifiy-bid-approved/${bidId}`).then((res) => {
        if (res.status === 200) {
          getProjectBidsNotificationContractor(userProfile?.country, token?.id);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  // set status bid approved
  const updateStatusNotifiyBidRejected = async (bidId) => {
    try {
      await axios.put(`${API_URL}account/update-notifiy-bid-rejected/${bidId}`).then((res) => {
        if (res.status === 200) {
          console.log(res);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  /*    matériel */

  // category
  const [materialCategory, setMaterialCategory] = useState([]);
  const [materialById, setMaterialById] = useState();

  const getMaterialCategory = async () => {
    try {
      await axios.get(`${API_URL}material-category/get-all`).then((res) => {
        if (res.status === 200) {
          setMaterialCategory(res.data);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  const getMaterialById = async (materialId) => {
    try {
      await axios.get(`${API_URL}material/get-by-id/${materialId}`).then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setMaterialById(res.data);
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  const createMaterial = async (
    material,
    category,
    taskId,
    unitPrice,
    description,
    unitNumber,
    unitOfMeasure,
    showToast,
    clearField,
  ) => {
    const lang = i18n.language;
    try {
      await axios
        .post(`${API_URL}material/create`, {
          category: category,
          taskId: taskId,
          materialName: material,
          unitPrice: unitPrice ? `${unitPrice}${lang === "fr" ? " FCFA" : " $"}` : "",
          description: description,
          unitNumber: unitNumber,
          unitOfMeasure: unitOfMeasure,
        })
        .then((res) => {
          if (res.status === 201) {
            clearField();
            showToast(t("PlanningProject.successfullyAddedMaterial"));

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            showToast(t("PlanningProject.failedToAddMaterial"));
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  const updateMaterial = async (
    materialId,
    material,
    category,
    unitPrice,
    description,
    unitNumber,
    unitOfMeasure,
    showToast,
    clearField,
  ) => {
    const lang = i18n.language;
    try {
      await axios
        .put(`${API_URL}material/update/${materialId}`, {
          category: category,
          materialName: material,
          unitPrice: unitPrice ? `${unitPrice}${lang === "fr" ? " FCFA" : " $"}` : "",
          description: description,
          unitNumber: unitNumber,
          unitOfMeasure: unitOfMeasure,
        })
        .then((res) => {
          if (res.status === 200) {
            clearField();
            showToast(t("common.theUpdateHasBeenCompletedSuccessfully"));

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            showToast(t("common.updateFailed"));
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  const deleteMaterial = async (materialId, showToast) => {
    try {
      await axios.delete(`${API_URL}material/delete/${materialId}`).then((res) => {
        if (res.status === 200) {
          showToast(t("common.deletionCompletedsuccessfully"));

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          showToast(t("common.deletionFailed"));
        }
      });
    } catch (error) {
      disconnect(error);
    }
  };

  // send message by user
  const sendMessageByUser = async (fullname, email, subject, message, showToast, setLoading) => {
    try {
      await axios
        .post(`${API_URL}user/send-message`, {
          fullname: fullname,
          email: email,
          subject: subject,
          message: message,
        })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            showToast(t("common.messageSentSuccessfully"));
          } else {
            setLoading(false);
            showToast(t("common.theMessageWasNotSentPleaseTryAgain"));
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // get designs
  const [designs, setDesigns] = useState([]);
  const getDesigns = async () => {
    try {
      await axios.get(`${API_URL}designs/get-all`).then((res) => {
        if (res.status === 200) {
          setDesigns(res.data);
        }
      });
    } catch (error) {
      return error;
    }
  };

  // update project status
  const updateStatusProject = async (projectId, status) => {
    try {
      await axios
        .put(`${API_URL}project/update-status/${projectId}`, {
          status: status,
          userId: token ? token.id : 0,
        })
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            console.log(res);
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update project status
  const updateStatusBlockProject = async (projectId, status, showToast) => {
    try {
      await axios
        .put(`${API_URL}project/update-status-block/${projectId}`, { status: status })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.msg === "lock") {
              showToast(t("common.projectSuccessfullyBlocked"));
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              showToast(t("common.projectSuccessfullyUnlocked"));
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          } else {
            showToast(t("common.thisActionFailedPleaseTryAgain"));
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };

  // update project status
  const updateStatusBlockUnBlockTask = async (taskId, status, showToast) => {
    try {
      await axios
        .put(`${API_URL}task/blocked-unblocked/${taskId}`, { status: status })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            if (res.data.msg === "lock") {
              showToast(t("common.taskSuccessfullyBlocked"));

              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              showToast(t("common.taskSuccessfullyUnlocked"));

              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          } else {
            showToast(t("common.thisActionFailedPleaseTryAgain"));
          }
        });
    } catch (error) {
      disconnect(error);
    }
  };


  /* *********** deuxième phase *********** */
  const [contractorInRegion, setContractorInRegion] = useState([]);
  
const verifyContractorInRegion = (country, city) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(`${API_URL}contractor/verifiy-in-region`, {
        country: country,
        city: city
      });

      resolve(res); // Résoudre la promesse avec les données obtenues de la requête
    } catch (error) {
      console.error("Erreur lors de la vérification des entrepreneurs dans la région :", error);
      reject(error); // Rejeter la promesse avec l'erreur obtenue
    }
  });
};

  const createRepairProject = (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(`${API_URL}create/repair-project`, formData);
        resolve(res);
      } catch (error) {
        console.error("Erreur lors de la création du projet :", error);
        reject(error.response);
      }
    })
  }

  const updateProject = async (formData) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await axios.put(`${API_URL}projet/update`, formData, config);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  const getRepairProjectInProgress = async (userId) => {
    return new Promise (( async (resolve, reject) => {
      try {
        const res = await axios.get(`${API_URL}repair-project/in-progress/${userId}`);
        if (res) {
          resolve(res);
        }
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    }))
  };


  const getRepairProjectByUser = async (userId) => {
    return new Promise (( async (resolve, reject) => {
      try {
        const res = await axios.get(`${API_URL}repair-project/list/${userId}`);
        if (res) {
          resolve(res);
        }
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    }))
  };

  const getProjectById = async (projectId) => {
   return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(`${API_URL}repair-project/get-by-id/${projectId}`);
      resolve(res);
    } catch (error) {
      disconnect(error);
      reject(error.response)
    }
   })
  };

  const deleteProject = async (id) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await axios.delete(`${API_URL}repair-project/delete/${id}`);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  const getRepairProjectByContractor = async (id) => {
    return new Promise (( async (resolve, reject) => {
      try {
        const res = await axios.get(`${API_URL}repair-project/list-by-contractor/${id}`);
        if (res) {
          resolve(res);
        }
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    }))
  };

  const getProjectByRegion = async (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(`${API_URL}repair-project/get-by-region`, formData);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response)
      }
     })
  };

  // submit bid
  const submitBid = async (formData) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await axios.post(`${API_URL}bid/submit-bid`, formData);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    });
  };

  // update bid
  const updateBid = async (formData) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await axios.put(`${API_URL}bid/update-bid`, formData);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  // deleted bid
  const deleteBid = async (bidId) => {
    return new Promise( async(resolve, reject) => {
      try {
        const res = await axios.delete(`${API_URL}bid/delete-bid/${bidId}`);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

   // approved bid
   const acceptedBid = async (formData) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await axios.put(`${API_URL}bid/accepted`, formData)
        resolve (res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  // rejected bid
  const rejectedBid = async (formData) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await axios.put(`${API_URL}bid/rejected`, formData);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response)
      }
    })
  };

   // deleted bid
   const getBidByProjet = async (projectId) => {
    return new Promise( async(resolve, reject) => {
      try {
        const res = await axios.get(`${API_URL}project/bids/${projectId}`);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  // get notification by user
  const getNotificationsByUser = async (userId) => {
    return new Promise( async(resolve, reject) => {
      try {
        const res = await axios.get(`${API_URL}notifications/by-user/${userId}`);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  const getNotificationsByContractor = async (id) => {
    return new Promise( async(resolve, reject) => {
      try {
        const res = await axios.get(`${API_URL}notifications/by-contractor/${id}`);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  const markNotificationsAsRead = async (formData) => {
    return new Promise( async(resolve, reject) => {
      try {
        const res = await axios.put(`${API_URL}notifications/mark-view`, formData);
        resolve(res);
      } catch (error) {
        disconnect(error);
        reject(error.response);
      }
    })
  };

  const contextData = {
    //user
    userProfile,
    updateUserProfile,
    sendMessageByUser,

    //project
    createProject,
    updateProject,
    deleteProject,
    getProjectSave,
    getProjectById,
    projectById,
    projetSave,
    projectByCountry,
    getProjectByCountry: getProjectByRegion,
    publishProject,
    unPublishedProject,
    updateStatusProject,
    updateStatusBlockProject,

    // task
    addTask,
    getTask,
    getTaskById,
    taskById,
    setTaskById,
    task,
    updateStatusTask,
    updateTask,
    deleteTask,
    approvedTask,
    rejectedTask,
    updateStatusBlockUnBlockTask,

    //contractor
    contractorByCountry,
    getContractorByCountry,

    //bid
    bidsByBuyer,
    bidsByContractor,
    bidById,
    submitBid,
    getBidByBuyer,
    getBidContractor,
    updateBid,
    deleteBid,
    acceptedBid,
    rejectedBid,
    getBidById,

    //notifications
    dataNotificationBuyer,
    dataNotificationContractor,
    updateStatusNotififyNewTask,
    updateStatusNotififyTaskInProgress,
    updateStatusNotififyTaskCompleted,
    updateStatusNotififyNewBid,
    updateStatusViewedProject,
    updateStatusNotifiyBidApproved,
    updateStatusNotifiyBidRejected,
    updateStatusNotifyTaskApproved,
    updateStatusNotifyTaskRejected,

    //material
    materialCategory,
    createMaterial,
    updateMaterial,
    deleteMaterial,
    getMaterialById,
    materialById,

    //design
    getDesigns,
    designs,

    /* ************** deuxième phase *********** */
    verifyContractorInRegion,
    contractorInRegion,
    createRepairProject,
    getRepairProjectByUser,
    getRepairProjectByContractor,
    getProjectByRegion,
    getBidByProjet,
    getRepairProjectInProgress,
    getNotificationsByContractor,
    getNotificationsByUser,
    markNotificationsAsRead,

    isMobile
  };

  useEffect(() => {
    if (token) {
      getUserProfile(token.id);
      console.log(token)
    }

    setLoading(false);

    const handleResize = () => {
      console.log(window.innerWidth < 1024);
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Vérifie initialement la taille de l'écran
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [task]);
  return (
    <GlobalContext.Provider value={contextData}>{loading ? null : children}</GlobalContext.Provider>
  );
};
