import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

export default function Loading() {
  return (
    <div className="card flex justify-content-center w-full h-full">
      <ProgressSpinner className="h-full" />
    </div>
  );
}
