import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import "./GroupBtnAuth.scss";
import DialogLayout from "../DialogLayout/DialogLayout";
import Register from "../authentification/Register/Register";
import Login from "../authentification/Login/Login";
import ConfirmAccount from "../authentification/ConfirmAccout/ConfirmAccout";
import AuthContext from "../../services/AuthContext";

export const GroupBtnAuth = (props) => {
  const { t } = useTranslation();
  const { logoutUser, token } = useContext(AuthContext);
  const [openDialogAuth, isOpenDialogAuth] = useState(false);
  const [authIndex, setAuthIndex] = useState(0);

  const renderAuthForm = () => {
    if (authIndex === 0) {
      return <Register isOpenDialogAuth={isOpenDialogAuth} setAuthIndex={setAuthIndex} />;
    } else if (authIndex === 1) {
      return <Login isOpenDialogAuth={isOpenDialogAuth} setAuthIndex={setAuthIndex} />;
    } else {
      return <ConfirmAccount isOpenDialogAuth={isOpenDialogAuth} />;
    }
  };

  const renderBtn = () => {
    if (!token) {
      return (
        <>
          <button
            type="button"
            className="GroupBtnAuth-btnLogin"
            onClick={() => {
              isOpenDialogAuth(true);
              setAuthIndex(1);
            }}
          >
            {t("TopBar.login")}
          </button>
        </>
      );
    } else {
      return (
        <button type="button" className="GroupBtnAuth-btnLogout" onClick={logoutUser}>
          {t("TopBar.logout")}
        </button>
      );
    }
  };

  return (
    <>
      <DialogLayout openPopup={openDialogAuth} isOpenPopup={isOpenDialogAuth}>
        {renderAuthForm()}
      </DialogLayout>
      <div className={`GroupBtnAuth ${props.class}`}>{renderBtn()}</div>
    </>
  );
};
