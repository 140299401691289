import plomb from "../assets/images/plomberie.png";
import cloture_repai from "../assets/images/repair_cloture.png";
import clean_tapis from "../assets/images/clean_tapis.png";
import technicien from "../assets/images/technicien.png";
import repair_gout from "../assets/images/repair_goutiere.png";
import repair_window from "../assets/images/porte_fenetre.png";
import repair_sol from "../assets/images/repair_sol.png";
import repair_toit from "../assets/images/toiture.png";
import repair_pelouse from "../assets/images/repair_jadin.png";
import paint from "../assets/images/peinture.png";

export const getTranslatedServices = (t) => [
  { img: plomb, name: t("common.plumbingRepairs"), initial_amount: 1254 },
  { img: technicien, name: t("common.electricalRepair"), initial_amount: 1350 },
  { img: repair_toit, name: t("common.roofRepair"), initial_amount: 1450 },
  { img: repair_window, name: t("common.windowAndDoorRepairs"), initial_amount: 1350 },
  { img: repair_sol, name: t("common.floorRepairs"), initial_amount: 1200 },
  { img: paint, name: t("common.interiorAndExteriorPainting"), initial_amount: 1400 },
  { img: repair_pelouse, name: t("common.maintenanceOfLawnsAndGardens"), initial_amount: 1300 },
  { img: cloture_repai, name: t("common.fenceAndGateRepairs"), initial_amount: 1250 },
  { img: repair_gout, name: t("common.cleaningGutters"), initial_amount: 1100 },
  { img: clean_tapis, name: t("common.carpetCleaning"), initial_amount: 1200 },
  { img: "img/service14.jpg", name: t("common.chimneyRepairs"), initial_amount: 1600 },
  { img: "img/service15.jpg", name: t("common.poolRepairs"), initial_amount: 1800 },
  { img: "img/service16.jpg", name: t("common.furnitureRepair"), initial_amount: 1350 },
  { img: "img/service11.jpg", name: t("common.repairOfExteriorFloorCoverings"), initial_amount: 1450 },
  { img: "img/service17.jpg", name: t("common.applianceRepair"), initial_amount: 1400 },
  { img: "img/service18.jpg", name: t("common.securitySystemInstallation"), initial_amount: 1600 },
  { img: "img/service19.jpg", name: t("common.repairOfInteriorFloorCoverings"), initial_amount: 1300 },
  { img: "img/service20.jpg", name: t("common.ceilingRepair"), initial_amount: 1450 },
  { img: "img/service4.jpg", name: t("common.heatingAndAirConditioningRepair"), initial_amount: 1550 },
  // Ajoutez d'autres services selon vos besoins
];
