import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";

import { BlockAuthSocial } from "../../BlockAuthSocial/BlockAuthSocial";
import SelectCountryField from "../../SelectCountryField/SelectCountryField";
import { validInputText } from "../../ControlField/controlField";
import AuthContext from "../../../services/AuthContext";

import "../Auth.scss";
import { useNavigate } from "react-router-dom";
import { ACTIVE_ACCOUNT, LOGIN } from "../../../constant/constant";
import { removeSpaces } from "../../removeSpace/removeSpace";
import { InputText } from "primereact/inputtext";
import { keepOnlyNumbers } from "../../keepOnlyNumbers/keepOnlyNumbers";
import showPassword from "../../ShowPwd/showPassword";
import { RadioButton } from "primereact/radiobutton";
import spinner from "../../../assets/images/SvgSpinnersBarsRotateFade.svg";
import { countries } from "../../countryList/countrylist";

const RegisterComponent = ({ isP, setAuthIndex }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { registerUser } = useContext(AuthContext);
  const [country, setCountry] = useState("Benin");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [role, setRole] = useState("");
  const [selectedPDFs, setSelectedPDFs] = useState([]);

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [msg, setMsg] = useState("");

  const renderButtonSubmit = () => {
    if (role === "customer" || selectedPDFs.length === 0) {
      return (
        <button type="button" className="FormAuth-btnSubmit" onClick={handleSubmit}>
          {t("Register.signUp")}
        </button>
      );
    } else if (role === "contractor") {
      return (
        <button type="button" className="FormAuth-btnSubmit">
          <input
            className="FormAuth-inputFile"
            type="file"
            accept=".pdf"
            multiple
            onChange={handlePDFChange}
          />
          {t("Register.verifyUploadDocument")}
        </button>
      );
    }
  };

  const handlePDFChange = (e) => {
    const files = e.target.files;
    if (files) {
      const pdfFiles = Array.from(files).filter((file) => {
        return file.type === "application/pdf";
      });

      if (pdfFiles.length === 0) {
        alert("Veuillez sélectionner des fichiers PDF");
        return;
      }
      setSelectedPDFs([...selectedPDFs, ...pdfFiles]);
    }
  };

  const deleteFile = (filename) => {
    setSelectedPDFs(selectedPDFs.filter((item) => item.name !== filename));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const passwdC = document.getElementById("passwdC_error");
    validInputText(fullname, "fullname_error", "text");
    validInputText(email, "email_error", "email");
    validInputText(password, "passwd_error", "pwd");
    console.log("phone", cellPhone);
    if (
      validInputText(fullname, "fullname_error", "text") &&
      validInputText(email, "email_error", "email") &&
      validInputText(password, "passwd_error", "pwd")
    ) {
      if (role) {
        if (password === passwordConf) {
          setLoading(true);
          const dialCode = countries.find(item => item.name.toLowerCase() === country.toLowerCase())

          const formData = new FormData();
          formData.append("fullname", fullname);
          formData.append("country", country);
          formData.append("phone", cellPhone ? `${dialCode?.dial_code}${removeSpaces(cellPhone)}` : null);
          formData.append("email", email);
          formData.append("password", password);
          formData.append("role", role);
          formData.append("lang", i18n.language);
          for (const file of selectedPDFs) {
            formData.append("pdfs", file);
          }
          formData.append("link", window.location.origin + ACTIVE_ACCOUNT);

          registerUser(formData, setVisible, setLoading, setMsg);
        } else {
          passwdC.textContent = t("Register.passwordsDontMatch");
        }
      }
    }
  };

  return (
    <>
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "500" }}
        className="text-center"
      >
        {
          loading ? (
            <img src={spinner} alt="" width={50} height={50} />
          ):(
            <p className="m-0">{msg}</p>
          )
        }
      </Dialog>
      <h1 className="FormAuth-h1">{t("common.register")}</h1>
      <div className="Role flex align-items-center">
        <span className="Role-label">{t("Register.iAm")}</span>
        <div className="flex flex-wrap gap-3 ml-2">
          <div className="flex align-items-center">
              <RadioButton inputId="customer" name="role" value="customer" 
                onChange={(e) => setRole(e.value)} 
                checked={role === 'customer'} />
              <label htmlFor="customer" className="ml-2 text-sm">{t("Register.customer")}</label>
          </div>
          <div className="flex align-items-center">
              <RadioButton inputId="contractor" name="role" value="contractor" 
                onChange={(e) => setRole(e.value)} 
                checked={role === 'contractor'} />
              <label htmlFor="contractor" className="ml-2 text-sm">{t("common.contractor")}</label>
          </div>
      </div>
      </div>
      <label className="Field">
        <input
          type="text"
          placeholder={t("Register.fullname")}
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
        />
        <span className="Field-error" id="fullname_error">
          {" "}
        </span>
      </label>
      <div className="PhoneField">
        <div className="PhoneField-blockInput Dflex">
          <SelectCountryField setCountry={setCountry} country={country} showLabel={false} />
          <InputText
            className="h-1rem"
            value={keepOnlyNumbers(cellPhone)}
            onChange={(e) => {
              setCellPhone(e.target.value);
            }}
            placeholder="Numéro whatsapp ( sans indicatif )"
          />
        </div>
        <span className="Field-error" id="phone_error">
          {" "}
        </span>
      </div>
      <label className="Field">
        <input
          type="email"
          placeholder={t("common.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <span className="Field-error" id="email_error">
          {" "}
        </span>
      </label>
      <label className="Field">
        {showPassword(".pwd")}
        <input
          className="pwd"
          type="password"
          placeholder={t("common.password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className="Field-error" id="passwd_error">
          {" "}
        </span>
      </label>
      <label className="Field">
        <input
          type="password"
          placeholder={t("Register.confirmPassord")}
          value={passwordConf}
          onChange={(e) => setPasswordConf(e.target.value)}
        />
        <span className="Field-error" id="passwdC_error">
          {" "}
        </span>
      </label>
      {renderButtonSubmit()}
      <BlockAuthSocial />
      <div className="FooterForm Dflex Dflex-col Dflex-alignCenter">
        <p className="FooterForm-p">
          {t("Register.alreadyHaveAnAccount?")}
          <button
            className="FooterForm-btnAuth"
            type="button"
            onClick={() => (isP === 2 ? navigate(LOGIN) : setAuthIndex(1))}
          >
            {t("Register.logInHere")}
          </button>
        </p>
        <div className="FooterForm-linksTerm Dflex Dflex-alignCenter">
          <a href="pol">{t("common.termsAndConditions")}</a>&nbsp;|&nbsp;
          <a href="pol">{t("common.privacyPolicy")}</a>
        </div>
      </div>
    </>
  );
};

export default RegisterComponent;
