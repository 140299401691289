import React, { Suspense, useContext, useEffect, useMemo, useState } from "react";
import { AiOutlineSetting, AiOutlineProject } from "react-icons/ai";
import { MdNotificationsNone } from "react-icons/md";
import { LuUsers } from "react-icons/lu";
import { LiaProjectDiagramSolid } from "react-icons/lia";
import { BiSolidOffer } from "react-icons/bi";
import { useTranslation } from "react-i18next";

import "./Layout.scss";
import Layout from "./Layout";
import {
  PROJECT_IN_PROGRESS_PAGE,
  PROJECT_SAVE,
  NOTIFICATIONS_BUYER,
} from "../constant/constant";
import SideBar from "../components/Sidebar/Sidebar";
import GlobalContext from "../services/GlobalContext";
import HandleGoBack from "../components/HandleGoBack/HandleGoBack";
import AuthContext from "../services/AuthContext";

const LayoutBuyer = ({ children, title }) => {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const { getNotificationsByUser } = useContext(GlobalContext);
  const [nbNotifications, setNbNotifications] = useState(0);

  const menuBuyer = [
    {
      path: PROJECT_IN_PROGRESS_PAGE,
      label: t("SideBar.projectInProgress"),
      icon: <AiOutlineProject />,
    },
    {
      path: PROJECT_SAVE,
      label: t("SideBar.yourProjects"),
      icon: <LiaProjectDiagramSolid />,
    },
    //{ path: CONTRACTOR_PAGE, label: t("SideBar.contractor"), icon: <LuUsers /> },
    /* {
      path: BIDS_PROJECT_PAGE,
      label: t("SideBar.bidsOnYourProjects"),
      icon: <BiSolidOffer />,
    }, */
    {
      path: NOTIFICATIONS_BUYER,
      label: t("SideBar.notifications"),
      icon: <MdNotificationsNone />,
      nbNotify: nbNotifications,
    },
    //{ path: SETTINGS_BUYER, label: t("SideBar.settings"), icon: <AiOutlineSetting /> },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getNotificationsByUser(token?.id);
        if (res) {
          if (res.status === 200) {
            const data = res.data;
            const filter = data && data.filter(item => item.isView === false);

            setNbNotifications(filter.length || 0);
          }
        }
      } catch (error) {
        throw new Error("Une erreur est survenue lors de la récupération des notifications");
      }
    }

    fetchData();
  },[]);

  return (
    <Layout isFooter={false}>
      <main className="Main">
        <SideBar linksArray={menuBuyer} showButton={true} />
        <div className="Main-center">
          <div className="Main-head flex align-items-center gap-3">
            <HandleGoBack />
            <h3 className="text-sm font-medium capitalize">{title}</h3>
          </div>
          <div className="Main-mainCenter">
            <Suspense fallback={"..."}>{children}</Suspense>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default LayoutBuyer;
