import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  BIDS_PROJECT_PAGE,
  BUYERS_ADMIN_PAGE,
  CREATE_PROJECT_PAGE,
  HOME_BUYER_PAGE,
  HOME_SCREEN_PAGE,
  PLANNING_PROJECT,
  PROJECT_IN_PROGRESS_PAGE,
  CONTRACTOR_ADMIN_PAGE,
  DESIGNS_PAGE,
  HOW_IT_WORK_PAGE,
  PROJECT_EXISTING_PAGE,
  YOUR_BIDS_PAGE,
  COMPLETED_PROJECTS_PAGE,
  PROJECT_SAVE,
  CONTRACTOR_PAGE,
  BOOKED_APPOINTMENTS_PAGE,
  NOTIFICATIONS_BUYER,
  SETTINGS_BUYER,
  NOTIFICATIONS_CONTRACTOR,
  SETTINGS_CONTRACTOR,
  ACTIVE_ACCOUNT,
  LOGIN,
  REGISTER,
  EDIT_PROJECT_PAGE,
  CONTRACTOR_PLANNING_PROJECT,
  PROJECT_PUBLISH,
  VERIFY_EMAIL,
  CONFIRM_EMAIL,
  RESET_PWD,
  OFFER_SUBMIT,
  PROFILE_BUYER,
  PROFILE_CONTRACTOR,
  CERTIFIED_CONTRACTOR,
  CONTACT_PAGE,
  BUYER_DETAILS_TASK_PAGE,
  BUYER_TASK_COMPLETED,
  BUYER_DETAILS_BID_PAGE,
  CONTRACTOR_DETAIL_PROJECT_PAGE,
  BUYER_DETAIL_PROJECT_PAGE,
  CONTRACTOR_DETAILS_BID_PAGE,
  CONTRACTOR_DETAILS_TASK_PAGE,
  GET_ESTIMATED,
  SERVICES_REPAIR,
  BUYER_PROJECT_BIDS,
  SERVICES,
} from "./constant/constant";
import Layout from "./layout/Layout";
import LayoutBuyer from "./layout/LayoutBuyer";
import LayoutAdmin from "./layout/LayoutAdmin";
import LayoutContractor from "./layout/LayoutContrator";
import LayoutAuth from "./layout/LayoutAuth";
import PrivateRouteBuyer from "./PrivateRouteBuyer";
import PrivateRouteContractor from "./PrivateRouteContractor";
import Loading from "./components/Loading/Loading";
import { useTranslation } from "react-i18next";
import { FiChevronRight } from "react-icons/fi";

const HomeScreen = lazy(() => import("./pages/HomeScreen/HomeScreen"));
const ActiveAccount = lazy(
  () => import("./components/authentification/ActiveAccount/ActiveAccount"),
);
const Login = lazy(() => import("./components/authentification/Login/Login2"));
const Register = lazy(() => import("./components/authentification/Register/Register2"));
const VerifyEmail = lazy(() => import("./components/authentification/ResetPwd/VerifyEmail"));
const ConfirmEmail = lazy(() => import("./components/authentification/ResetPwd/ConfirmEmail"));
const ResetPwd = lazy(() => import("./components/authentification/ResetPwd/ResetPwd"));
const DesignsPage = lazy(() => import("./pages/Designs/index"));
const BuyerProfile = lazy(() => import("./pages/BuyerPages/Profile/Profile"));
const HowItWorkPage = lazy(() => import("./pages/HowItWork/HowItWork"));
const CertifiedContractor = lazy(() => import("./pages/CertifiedContractor/CertifiedContractor"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const GetEstimated = lazy(() => import("./pages/GetEstimated/GetEstimated"));
const ServiceRepair = lazy(() => import("./pages/Services/ServicesRepairStep"));
const Service = lazy(() => import("./pages/Services/Services"));

// const AccountPage = lazy(() => import("./pages/ContratorPages/Account/Account"));
// const FooterPage = lazy(() => import("./pages/footer"));

// buyer pages
const ProjectInProgress = lazy(
  () => import("./pages/BuyerPages/ProjectInProgress/ProjectInProgress"),
);
const CreateProject = lazy(() => import("./pages/BuyerPages/AddProject/AddProject"));
const HomeBuyer = lazy(() => import("./pages/BuyerPages/index"));
const PlanningProject = lazy(() => import("./pages/BuyerPages/PlanningProjet/PlanningProjet"));
const ProjectBids = lazy(() => import("./pages/BuyerPages/ProjetBids/ProjectBids"));
const ProjectsSave = lazy(() => import("./pages/BuyerPages/SaveProject/SaveProject"));
const BuyerContractor = lazy(() => import("./pages/BuyerPages/Contractor/Contractor"));
const BuyerNotifications = lazy(() => import("./pages/BuyerPages/Notification/Notification"));
const BuyerSettings = lazy(() => import("./pages/BuyerPages/Settings/SettingsBuyer"));

const BuyerNotificationDetailsTaskPage = lazy(
  () => import("./pages/Project/Task/ViewDetailTask/ViewDetailTask"),
);
const BuyerNotificationCompletedTaskPage = lazy(
  () => import("./pages/Project/Task/CompletedTask/CompletedTask"),
);
const BuyerDetailsOfferPage = lazy(() => import("./pages/Project/DetailsOffer/DetailsOfferBuyer"));
const ContractorDetailsOfferPage = lazy(
  () => import("./pages/Project/DetailsOffer/DetailsOfferContractor"),
);

const DetailsProjectPage = lazy(
  () => import("./pages/Project/ViewDetailsProject/ViewDetailsProject2"),
);

const DetailsProjectPage2 = lazy(
  () => import("./pages/Project/ViewDetailsProject/ViewDetailsProject"),
);

// contractor pages
const ProjectExisting = lazy(
  () => import("./pages/ContratorPages/ProjectsEixisting/ProjectsEixisting"),
);
const BidsContractor = lazy(() => import("./pages/ContratorPages/Bids/Bids"));
const CompletedProjects = lazy(
  () => import("./pages/ContratorPages/CompletedProjects/CompletedProjects"),
);
const BookedAppointments = lazy(
  () => import("./pages/ContratorPages/BookingAppointments/BookingAppointments"),
);
const ContractorNotifications = lazy(
  () => import("./pages/ContratorPages/Notifications/NotificationsContractor"),
);
const ContractorSetting = lazy(() => import("./pages/ContratorPages/Settings/SettingsContractor"));
const ProjectPublish = lazy(() => import("./pages/ContratorPages/ProjectPublish/ProjectPublish"));
const OfferSubmit = lazy(() => import("./pages/ContratorPages/OfferSubmit/OfferSubmit"));
const BidsByProject = lazy(() => import("./pages/BuyerPages/BidsByProjet/BidsByProjet"));

// // admin pages
const BuyersAdmin = lazy(() => import("./pages/AdminPages/Buyers/Buyers"));
const ContractorAdmin = lazy(() => import("./pages/AdminPages/Contractors/Contractors"));

const AppRouter = () => {
  const { t } = useTranslation();

  return (
    <Router>
      <Routes>
        <Route
          path={HOME_SCREEN_PAGE}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <HomeScreen />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={REGISTER}
          element={
            <LayoutAuth>
              <Suspense fallback={<Loading />}>
                <Register />
              </Suspense>
            </LayoutAuth>
          }
        />

        <Route
          path={LOGIN}
          element={
            <LayoutAuth>
              <Suspense fallback={<Loading />}>
                <Login />
              </Suspense>
            </LayoutAuth>
          }
        />

        <Route
          path={VERIFY_EMAIL}
          element={
            <LayoutAuth>
              <Suspense fallback={<Loading />}>
                <VerifyEmail />
              </Suspense>
            </LayoutAuth>
          }
        />

        <Route
          path={CONFIRM_EMAIL}
          element={
            <LayoutAuth>
              <Suspense fallback={<Loading />}>
                <ConfirmEmail />
              </Suspense>
            </LayoutAuth>
          }
        />

        <Route
          path={RESET_PWD}
          element={
            <LayoutAuth>
              <Suspense fallback={<Loading />}>
                <ResetPwd />
              </Suspense>
            </LayoutAuth>
          }
        />

        <Route
          path={ACTIVE_ACCOUNT}
          element={
            <LayoutAuth>
              <Suspense fallback={<Loading />}>
                <ActiveAccount />
              </Suspense>
            </LayoutAuth>
          }
        />

        <Route
          path={DESIGNS_PAGE}
          element={
            <Layout>
              <Suspense fallback={"..."}>
                <DesignsPage />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={HOW_IT_WORK_PAGE}
          element={
            <Layout>
              <Suspense fallback={"..."}>
                <HowItWorkPage />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={CERTIFIED_CONTRACTOR}
          element={
            <Layout>
              <Suspense fallback={"..."}>
                <CertifiedContractor />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={CONTACT_PAGE}
          element={
            <Layout>
              <Suspense fallback={"..."}>
                <ContactUs />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={GET_ESTIMATED}
          element={
            <Layout>
              <Suspense fallback={"..."}>
                <GetEstimated />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={SERVICES_REPAIR}
          element={
            <Layout isFooter={false}>
              <Suspense fallback={"..."}>
                <ServiceRepair />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={SERVICES}
          element={
            <Layout>
              <Suspense fallback={"..."}>
                <Service />
              </Suspense>
            </Layout>
          }
        />

        {/* buyer pages */}

        <Route
          path={PROFILE_BUYER}
          element={
            //<PrivateRouteBuyer>
              <LayoutBuyer title={t("common.profil")}>
                <Suspense fallback={<Loading />}>
                  <BuyerProfile />
                </Suspense>
              </LayoutBuyer>
            //</PrivateRouteBuyer>
          }
        />

        <Route
          path={PROFILE_CONTRACTOR}
          element={
            <PrivateRouteContractor>
              <LayoutContractor title={t("common.profil")}>
                <Suspense fallback={<Loading />}>
                  <BuyerProfile />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={PROJECT_IN_PROGRESS_PAGE}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer title={t("SideBar.projectInProgress")}>
                <Suspense fallback={<Loading />}>
                  <ProjectInProgress />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        <Route
          path={CREATE_PROJECT_PAGE}
          element={
            <LayoutBuyer>
              <Suspense fallback={<Loading />}>
                <CreateProject />
              </Suspense>
            </LayoutBuyer>
          }
        />

        <Route
          path={EDIT_PROJECT_PAGE}
          element={
            <LayoutBuyer>
              <Suspense fallback={<Loading />}>
                <CreateProject />
              </Suspense>
            </LayoutBuyer>
          }
        />

        <Route
          path={HOME_BUYER_PAGE}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer>
                <Suspense fallback={<Loading />}>
                  <HomeBuyer />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        <Route
          path={PLANNING_PROJECT}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer title={`${t("SideBar.projectInProgress")} > ${t("common.details")}`}>
                <Suspense fallback={<Loading />}>
                  <PlanningProject />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        <Route
          path={CONTRACTOR_PLANNING_PROJECT}
          element={
            <PrivateRouteContractor>
              <LayoutContractor title={`${t("SideBar.existingProjects")} > ${t("common.details")}`}>
                <Suspense fallback={<Loading />}>
                  <PlanningProject />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={BIDS_PROJECT_PAGE}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer title={t("SideBar.bidsOnYourProjects")}>
                <Suspense fallback={<Loading />}>
                  <ProjectBids />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        <Route
          path={PROJECT_SAVE}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer title={t("SideBar.yourProjects")}>
                <Suspense fallback={<Loading />}>
                  <ProjectsSave />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        <Route
          path={BUYER_PROJECT_BIDS}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer title={t("SideBar.offers")}>
                <Suspense fallback={<Loading />}>
                  <BidsByProject />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        <Route
          path={CONTRACTOR_PAGE}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer title={t("SideBar.contractor")}>
                <Suspense fallback={<Loading />}>
                  <BuyerContractor />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        <Route
          path={NOTIFICATIONS_BUYER}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer title={t("SideBar.notifications")}>
                <Suspense fallback={<Loading />}>
                  <BuyerNotifications />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        <Route
          path={SETTINGS_BUYER}
          element={
            <PrivateRouteBuyer>
              <LayoutBuyer title={t("SideBar.settings")}>
                <Suspense fallback={<Loading />}>
                  <BuyerSettings />
                </Suspense>
              </LayoutBuyer>
            </PrivateRouteBuyer>
          }
        />

        {/* contractor pages */}

        <Route
          path={PROJECT_EXISTING_PAGE}
          element={
            <PrivateRouteContractor>
              <LayoutContractor title={t("SideBar.yourProjects")}>
                <Suspense fallback={<Loading />}>
                  <ProjectExisting />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={PROJECT_PUBLISH}
          element={
            <PrivateRouteContractor>
              <LayoutContractor title={t("SideBar.projectPublish")}>
                <Suspense fallback={<Loading />}>
                  <ProjectPublish />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={YOUR_BIDS_PAGE}
          element={
            <PrivateRouteContractor>
              <LayoutContractor title={t("SideBar.yourBids")}>
                <Suspense fallback={<Loading />}>
                  <BidsContractor />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={COMPLETED_PROJECTS_PAGE}
          element={
            <PrivateRouteContractor>
              <LayoutContractor title={t("SideBar.completedProjects")}>
                <Suspense fallback={<Loading />}>
                  <CompletedProjects />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={BOOKED_APPOINTMENTS_PAGE}
          element={
            <PrivateRouteContractor>
              <LayoutContractor>
                <Suspense fallback={<Loading />}>
                  <BookedAppointments />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={OFFER_SUBMIT}
          element={
            <PrivateRouteContractor>
              <LayoutContractor title={t("OfferSubmit.submitABid")}>
                <Suspense fallback={<Loading />}>
                  <OfferSubmit />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={NOTIFICATIONS_CONTRACTOR}
          element={
            <PrivateRouteContractor>
              <LayoutContractor title={t("SideBar.notifications")}>
                <Suspense fallback={<Loading />}>
                  <ContractorNotifications />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        <Route
          path={SETTINGS_CONTRACTOR}
          element={
            <PrivateRouteContractor>
              <LayoutContractor>
                <Suspense fallback={<Loading />}>
                  <ContractorSetting />
                </Suspense>
              </LayoutContractor>
            </PrivateRouteContractor>
          }
        />

        {/* admin pages */}
        <Route
          path={BUYERS_ADMIN_PAGE}
          element={
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <BuyersAdmin />
              </Suspense>
            </LayoutAdmin>
          }
        />

        <Route
          path={CONTRACTOR_ADMIN_PAGE}
          element={
            <LayoutAdmin>
              <Suspense fallback={<Loading />}>
                <ContractorAdmin />
              </Suspense>
            </LayoutAdmin>
          }
        />

        <Route
          path={BUYER_DETAILS_TASK_PAGE}
          element={
            <LayoutBuyer title={`${t("SideBar.notifications")}  > ${t("common.taskDetails")}`}>
              <Suspense fallback={"..."}>
                <BuyerNotificationDetailsTaskPage />
              </Suspense>
            </LayoutBuyer>
          }
        />

        <Route
          path={CONTRACTOR_DETAILS_TASK_PAGE}
          element={
            <LayoutContractor title={`${t("SideBar.notifications")}  > ${t("common.taskDetails")}`}>
              <Suspense fallback={"..."}>
                <BuyerNotificationDetailsTaskPage />
              </Suspense>
            </LayoutContractor>
          }
        />

        <Route
          path={BUYER_TASK_COMPLETED}
          element={
            <LayoutBuyer>
              <Suspense fallback={"..."}>
                <BuyerNotificationCompletedTaskPage />
              </Suspense>
            </LayoutBuyer>
          }
        />
        <Route
          path={BUYER_DETAILS_BID_PAGE}
          element={
            <LayoutBuyer title={`${t("SideBar.notifications")}  > ${t("common.offerDetails")}`}>
              <Suspense fallback={"..."}>
                <BuyerDetailsOfferPage />
              </Suspense>
            </LayoutBuyer>
          }
        />

        <Route
          path={CONTRACTOR_DETAILS_BID_PAGE}
          element={
            <LayoutContractor
              title={`${t("SideBar.notifications")}  > ${t("common.offerDetails")}`}
            >
              <Suspense fallback={"..."}>
                <ContractorDetailsOfferPage />
              </Suspense>
            </LayoutContractor>
          }
        />

        <Route
          path={CONTRACTOR_DETAIL_PROJECT_PAGE}
          element={
            <LayoutContractor
              title={`${t("common.project")} > ${t("common.detailsOfProject")}`}
            >
              <Suspense fallback={"..."}>
                <DetailsProjectPage />
              </Suspense>
            </LayoutContractor>
          }
        />

        <Route
          path={BUYER_DETAIL_PROJECT_PAGE}
          element={
            <LayoutBuyer
              title={`${t("SideBar.yourProjects")} > ${t("common.detailsOfProject")}`}
            >
              <Suspense fallback={"..."}>
                <DetailsProjectPage2 />
              </Suspense>
            </LayoutBuyer>
          }
        />
        {/* <Route
          path={ACCOUNT_PAGE}
          element={
            <LayoutBuyer>
              <Suspense fallback={"..."}>
                <AccountPage />
              </Suspense>
            </LayoutBuyer>
          }
        />
        <Route
          path={FOOTER_PAGE}
          element={
            <LayoutAdmin>
              <Suspense fallback={"..."}>
                <FooterPage />
              </Suspense>
            </LayoutAdmin>
          }
        /> */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
