import React from "react";
import Auth from "../Auth";

import "../Auth.scss";
import RegisterComponent from "./RegisterComponent";

const Register = ({ isOpenDialogAuth, setAuthIndex }) => {
  return (
    <Auth isOpenDialogAuth={isOpenDialogAuth}>
      <RegisterComponent isP={1} setAuthIndex={setAuthIndex} />
    </Auth>
  );
};

export default Register;
