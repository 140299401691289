import React, { useContext, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { LuUserCircle2 } from "react-icons/lu";

import "./NavBar.scss";
import AuthContext from "../../services/AuthContext";
import { useNavigate } from "react-router-dom";
import { PROJECT_EXISTING_PAGE, PROJECT_SAVE } from "../../constant/constant";

export const NavBar = forwardRef(({ linksArray }, ref, ref2) => {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setActiveLink();
  }, []);

  const setActiveLink = () => {
    const allLinks = document.querySelectorAll(".NavBar-li a");
    const path = window.location.pathname;
    allLinks.forEach((link) => {
      if (link.getAttribute("href") === path) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  };

  return (
    <nav className="NavBar" ref={ref}>
      <ul className="NavBar-ul Dflex Dflex-alignCenter">
        {linksArray.map((list, index) => (
          <li className="NavBar-li" key={index.toString()}>
            <a href={list.path}>{list.label}</a>
          </li>
        ))}
        <div className="NavBar-right flex flex-column">
         {/*  <button type="button" className="NavBar-btnEstimate Header-btnEstimate">
            {t("NavBar.getEstimate")}
          </button> */}
          <button
            ref={ref2}
            className="NavBar-btnProfile Header-btnProfile"
            type="button"
            onClick={() => navigate(token && token.userRole === "contractor" ? PROJECT_EXISTING_PAGE:PROJECT_SAVE)}
          >
            <LuUserCircle2 />{t("common.myAccount")}
          </button>
        </div>
      </ul>
    </nav>
  );
});
