import React from "react";

import "./DialogLayout.scss";

export const DialogLayout = ({ children, openPopup, isOpenPopup }) => {
  const closePopup = async (e) => {
    const overlay = document.querySelector(".DialogLayout");
    if (e.target !== overlay) return;
    isOpenPopup(!openPopup);
  };

  return (
    <div
      className="DialogLayout"
      style={openPopup ? { display: "flex" } : { display: "none" }}
      onClick={closePopup}
      onKeyUp={closePopup}
    >
      {children}
    </div>
  );
};

export default DialogLayout;
