import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { LuUserCircle2 } from "react-icons/lu";

import {
  CERTIFIED_CONTRACTOR,
  CONTACT_PAGE,
  DESIGN,
  DESIGNS_PAGE,
  GET_ESTIMATED,
  HOME_SCREEN_PAGE,
  HOW_IT_WORK,
  MEET_THE_TEAM,
  PROFILE_BUYER,
  PROFILE_CONTRACTOR,
  PROJECT_EXISTING_PAGE,
  PROJECT_IN_PROGRESS_PAGE,
  PROJECT_SAVE,
  SERVICES,
} from "../constant/constant";
import { TopBar } from "../components/TopBar/TopBar";
import AuthContext from "../services/AuthContext";

import logo from "../assets/images/logo.webp";

import "./Layout.scss";
import { useNavigate } from "react-router-dom";
import FooterCommponent from "../components/Footer/Footer";
import { FiMenu } from "react-icons/fi";
import { NavBar } from "../components/NavBar/NavBar";

const Layout = ({ children, isFooter = true }) => {
  const { t } = useTranslation();
  const navbarRef = useRef();
  const btnAccountRef = useRef();
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showNav, isShowNav] = useState(true);

  const linksHomeSreen = [
    { path: HOME_SCREEN_PAGE, label: t("NavBar.home") },
    { path: SERVICES, label: "Nos services" },
    /* { path: HOW_IT_WORK, label: t("NavBar.howitworks") },
    { path: DESIGNS_PAGE, label: t("NavBar.Designs") },
    { path: CERTIFIED_CONTRACTOR, label: t("NavBar.certifiedContractors") },
    token ? { path: GET_ESTIMATED, label: t("NavBar.getEstimate") } : "", */
    // { path: CONTACT_PAGE, label: t("common.contactUs") },
  ];

  const openNavBar = () => {
    if (navbarRef.current) {
      navbarRef.current.classList.toggle("NavBar--open");
    }
  };

  const renderBtn = () => {
    if (token) {
      console.log("otken")
      const url = window.location.pathname;
      if (url.includes("buyer") || url.includes("buyers") || url.includes("contractor")) {
        console.log(url)
        return (
          <button
            ref={btnAccountRef}
            className="Header-btnProfile"
            type="button"
            onClick={() => navigate(token && token.userRole === "contractor" ? PROFILE_CONTRACTOR:PROFILE_BUYER)}          >
            <LuUserCircle2 />{t("common.profil")}
          </button>
        );
      }
      return (
        <button
          ref={btnAccountRef}
          className="Header-btnProfile"
          type="button"
          onClick={() => navigate(token && token.userRole === "contractor" ? PROJECT_EXISTING_PAGE:PROJECT_SAVE)}          >
          <LuUserCircle2 />{t("common.myAccount")}
        </button>
      );
    } /* else {
      return (
        <button
          type="button"
          className="Header-btnEstimate"
          onClick={() => navigate(GET_ESTIMATED)}
        >
          {t("NavBar.getEstimate")}
        </button>
      );
    } */
  };

  useEffect(() => {
    const url = window.location.pathname;

    if (url.includes("buyer") || url.includes("buyers") || url.includes("contractor")) {
      isShowNav(false);
      
      if (btnAccountRef.current) {
        //btnAccountRef.current.classList.add("NavBar--hide")
      }
    }
  },[])

  return (
    <div className="Layout">
      <header className="Header w-full">
        <TopBar />
        <div className="Header-menuBar PaddingLeftRight w-full TopBar Dflex Dflex-alignCenter Dflex-justifySpaceBetween">
          <li className="Header-linklogo">
            {showNav && <FiMenu className="Header-sideBarIcon" onClick={openNavBar} />}
            <a href="/" className="">
              <img src={logo} alt="" /> <span>E-CON</span>
            </a>
          </li>
          {showNav && <NavBar ref={navbarRef} ref2={btnAccountRef} linksArray={linksHomeSreen} />}
          <div className="flex flex-column">{renderBtn()}</div>
        </div>
      </header>
      <div className="Layout-body"
      style={{
        paddingBottom: isFooter ? "100px":"0"
      }}>{children}</div>
      {isFooter ? <FooterCommponent />:""}
    </div>
  );
};

export default Layout;
