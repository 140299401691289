import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { GroupBtnAuth } from "../GroupBtnAuth/GroupBtnAuth";
import { CREATE_PROJECT_PAGE, PROFILE_BUYER, PROFILE_CONTRACTOR } from "../../constant/constant";

import "./Sidebar.scss";
import AuthContext from "../../services/AuthContext";
import { LuUserCircle2 } from "react-icons/lu";

export const SideBar = ({ linksArray, showButton }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useState(AuthContext); 

  useEffect(() => {
    setActiveLink();
  });

  const openSideBar = () => {
    const sidebar = document.querySelector(".Sidebar");
    sidebar.style.display = "none";
  };

  const setActiveLink = () => {
    const allLink = document.querySelectorAll(".Sidebar-li");
    const path = window.location.href;
    for (const link of allLink) {
      const a = link.querySelector(".Sidebar-a");
      if (a.href === path) {
        a.classList.add("active");
      } else {
        a.classList.remove("active");
      }
    }
  };

  return (
    <div className="Sidebar">
      <button type="button" className="Sidebar-close" onClick={openSideBar}>
        &times;
      </button>
      {showButton === true ? (
        <button
          className="Sidebar-btnCreateProjet flex align-items-center"
          type="button"
          onClick={() => navigate(CREATE_PROJECT_PAGE)}
        >
          <AiFillPlusCircle /> {t("SideBar.newProject")}
        </button>
      ) : null}
      <nav className="Sidebar-nav">
        <ul className="Sidebar-ul">
          {linksArray.map((list, index) => (
            <li
              className="Sidebar-li"
              onClick={() => setActiveLink(index)}
              onKeyUp={() => setActiveLink(index)}
              key={index.toString()}
            >
              {list.nbNotify ? (
                <span className="Sidebar-nb">{list.nbNotify < 5 ? list.nbNotify : "7+"}</span>
              ) : null}
              <a className="Sidebar-a" href={list.path}>
                <div className="Sidebar-text">
                  {list.icon} <p className="Sidebar-p">{list.label}</p>
                </div>
                <IoMdArrowDropright className="Sidebar-arrow" />
              </a>
            </li>
          ))}
        </ul>
        <div className="Sidebar-foot">
          <button
              className="Sidebar-btnProfile"
              type="button"
              onClick={() => navigate(token && token.userRole === "contractor" ? PROFILE_CONTRACTOR:PROFILE_BUYER)}          >
              <LuUserCircle2 />{t("common.profil")}
          </button>
          <GroupBtnAuth />
        </div>
       
      </nav>
    </div>
  );
};

export default SideBar;
