import React from "react";

import "../Auth.scss";
import Auth from "../Auth";
import LoginComponent from "./LoginComponent";

const Login = ({ isOpenDialogAuth, setAuthIndex }) => {
  return (
    <Auth isOpenDialogAuth={isOpenDialogAuth}>
      <LoginComponent setAuthIndex={setAuthIndex} nb={1} />
    </Auth>
  );
};

export default Login;
